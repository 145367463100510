@import "../functions/buttons";
@import "../mixins/buttons";
@import "../variables/color-palette";

$arag-button-types: (
  "primary": (
    "background": $color-arag-yellow,
    "border": $color-elephant,
    "hover-border": $color-elephant,
    "active-border": $color-elephant,
    "hover-background": $color-parakeet,
    "active-background": #ffc107,
    "focus-border": $color-navy-blue,
    "color": $color-crow,
  ),
  "secondary": (
    "background": $color-arag-blue,
    "hover-background": $color-bowerbird,
    "active-background": $color-bowerbird,
    "focus-border": $color-sail,
    "color": $color-swan,
  ),
  "light": (
    "background": $color-swan,
    "border": $color-koala,
    "hover-background": $color-dove,
    "hover-border": $color-koala,
    "active-background": $color-shoebill,
    "active-border": $color-koala,
    "focus-border": $color-peacock,
  ),
  "danger": (
    "background": $color-ladybug,
    "hover-background": $color-cardinal,
    "active-background": $color-cardinal,
    "focus-border": $color-coral-candy,
    "color": $color-swan,
  ),
  "success": (
    "background": $color-snake,
    "hover-background": $color-alligator,
    "active-background": $color-alligator,
    "focus-border": $color-alligator,
    "color": $color-swan,
  ),
  "link": (
    "background": transparent,
    "hover-background": $color-beluga,
    "active-background": $color-beluga,
    "focus-border": $color-peacock,
  ),
  "implied": (
    "background": transparent,
    "hover-background": $color-anchovy,
    "active-background": $color-dolphin,
    "focus-border": $color-peacock,
  ),
  "subtle": (
    "background": transparent,
    "hover-background": $color-anchovy,
    "active-background": $color-dolphin,
    "focus-border": $color-peacock,
  ),
) !default;


//
// Customize buttons
//

// @mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
@each $type, $button in $arag-button-types {
  @include arag-button-variant(
    $type,
    map-get($button, "background"),
    map-get($button, "border"),
    map-get($button, "hover-background"),
    map-get($button, "hover-border"),
    map-get($button, "active-background"),
    map-get($button, "active-border"),
    map-get($button, "focus-background"),
    map-get($button, "focus-border"),
    map-get($button, "color")
  );
}

// the danger button hover/focus background crossed a contrast threshold and Bootstrap changes the font color dark, so change it back...
.btn-danger:hover,
.btn-danger.hover,
.btn-danger:focus,
.btn-danger.focus {
  color: $color-swan;
}

.btn-light:focus,
.btn-light.focus {
  border-color: $color-swan;

  &.btn-light:hover:not(:active),
  &.btn-light.hover:not(.active) {
    border-color: $color-peacock;
  }
}


.btn-link {
  font-weight: $nav-link-font-weight;
  color: $color-peacock;

  &:focus,
  &.focus {
    color: $color-crow;
    text-decoration: none;
  }

  &:hover,
  &.hover {
    color: $color-peacock;
    text-decoration: none;
  }

  &:active:not(:disabled):not(.disabled) {
    color: $color-peacock;
  }
}

.btn-implied {
  color: $color-crow;
}

.btn-subtle {
  color: $color-elephant;
  text-decoration: underline;

  &:hover {
    color: $color-elephant;
    text-decoration: underline;
  }

  &:active:not(:disabled):not(.disabled) {
    color: $color-elephant;
  }
}

.btn.arag-icon {
  padding-right: $space-one;
  padding-left: $space-one;
  white-space: nowrap;

  &:not(.arag-icon-pos-right) {
    &.btn-lg {
      @include arag-icon-add($size: "lg", $position: "left");
    }

    &.btn-sm {
      padding-right: $space-three-quarters;
      padding-left: $space-three-quarters;
      @include arag-icon-add($size: "sm", $position: "left");
    }

    &::before {
      text-align: left;
    }
  }

  &.arag-icon-pos-right {
    &.btn-lg {
      @include arag-icon-add($size: "lg", $position: "right");
    }

    &.btn-sm {
      padding-right: $space-three-quarters;
      padding-left: $space-three-quarters;
      @include arag-icon-add($size: "sm", $position: "right");
    }

    &::after {
      text-align: right;
    }
  }
}

.btn-left {
  @include btn-icon-style-and-lookup("left", "left");

  @each $name, $icon in $arag-icons {
    &.icon-#{$name}::before {
      @include arag-icon($name);
    }
  }
}

.btn-right {
  @include btn-icon-style-and-lookup("right", "right");

  @each $name, $icon in $arag-icons {
    &.icon-#{$name}::after {
      @include arag-icon($name);
    }
  }
}

.btn-close {
  @include btn-icon-style-and-lookup("close", "right");
}

.btn-add {
  @extend .btn-success;
  @include btn-icon-style-and-lookup("plus-circle", "right");

  &.btn-left {
    @include btn-icon-style-and-lookup("plus-circle", "left");
    &::after {
      display: none;
    }
  }
}

.btn-remove {
  @extend .btn-danger;
  @include btn-icon-style-and-lookup("danger", "right");

  &.btn-left {
    @include btn-icon-style-and-lookup("danger", "left");
    &::after {
      display: none;
    }
  }
}

.btn-help {
  @extend .btn-light;
  @include btn-icon-style-and-lookup("help", "left");
}

.btn-edit {
  @extend .btn-light;
  @include btn-icon-style-and-lookup("edit", "right");
}

.btn-phone-number {
  @extend .btn-implied;
  @include btn-icon-style-and-lookup("phone", "left");
}

// semi-experimental container for auto-arranging buttons
.action-button-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-top: $space-one-and-half;
  padding-bottom: $space-half;

  .btn {
    margin-bottom: $space-one-and-half;

    &:first-of-type {
      margin-right: 0;
    }

    &:last-of-type {
      margin-left: 0;
    }
  }

  .primary-actions {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;

    .btn {
      margin-left: $space-one-and-half;
    }
  }
}

button:focus:not(:focus-visible) {
  outline: 5px auto -webkit-focus-ring-color;
}

.btn {
  font-size: 1.6rem;
  white-space: nowrap;
}

.btn-sm {
  font-size: 1.2rem;
  --bs-btn-line-height: 1.667;
}

.btn-lg {
  font-size: 1.8rem;
  --bs-btn-line-height: 1.556;
}

// replicate how dropdown toggles were focus colored without impacting all buttons
.show {
  > .btn-light.dropdown-toggle {
    background-color: arag-button-property("light", "active-background");
  }
  > .btn-primary.dropdown-toggle {
    background-color: arag-button-property("primary", "active-background");
  }
  > .btn-secondary.dropdown-toggle {
    background-color: arag-button-property("secondary", "active-background");
  }
  > .btn-danger.dropdown-toggle {
    background-color: arag-button-property("danger", "active-background");
  }
}
