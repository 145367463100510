@import "../variables";

.icon-card-input-columns-1,
.icon-card-input-columns-2,
.icon-card-input-columns-3,
.icon-card-input-columns-4,
.icon-card-input-columns-5,
.icon-card-input-columns-6 {
  display: grid;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(md) {
    gap: calc($grid-gutter-width / 2) $grid-gutter-width;
  }
}

@include media-breakpoint-up(md) {
  .icon-card-input-columns-2,
  .icon-card-input-columns-4,
  .icon-card-input-columns-5 {
    grid-template-columns: 1fr 1fr;
  }

  .icon-card-input-columns-3,
  .icon-card-input-columns-6 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include media-breakpoint-up(lg) {
  .icon-card-input-columns-4{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .icon-card-input-columns-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .icon-card-input-columns-6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
