.expander,
.card-deck-expander {
  > arag-expander-card > .card {
    margin: 0;
    border-top: 0;
    border-radius: 0;
  }

  > .card-header {
    border: $card-border-width solid $card-border-color;
    border-radius: $card-border-radius $card-border-radius 0 0;

    &:empty {
      display: none;
    }

    &.header-icon {
      @each $icon, $content in $arag-icons {
        &.header-icon-#{$icon} .deck-header {
          @include arag-icon-init("md", "right", $vertical-align: "middle", $margin: $space-half);
          //stylelint-disable-next-line selector-max-class
          &::before {
            padding-right: $space-quarter;
            color: $color-elephant;
            @include arag-icon($icon);
          }
        }
      }
    }

    > .deck-header {
      line-height: 1.5;  // this needs to match .card-header so when placed next to each other, the header height is the same
      color: $card-cap-color;
    }
  }
}

.card {
  > .horizontal {
    .preview-row {
      padding-bottom: 0;
    }
    .preview-content {
      padding-right: $card-spacer-x;
    }
  }

  .preview-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: $card-spacer-x;

    .expander-link {
      // when .expander-link is positioned to the right of the preview content (both are inside .preview-row), reset its position
      transform: translateX(0);
    }
  }

  .hidden-row {
    display: none;
  }
  .expander-link {
    padding: $space-quarter $space-half;
    color: $color-peacock;
    white-space: nowrap;
    background-color: $color-swan;
    border: 0;
    outline: 0;
    box-shadow: none;
    transform: translateX(-$space-half);  // should match left padding for alignment with content above
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: 2px solid $color-peacock;
    }
    @include arag-icon-init("md", "right", $vertical-align: "middle", $margin: $space-half);
    &::after {
      @include arag-icon("down");
    }
  }
  &.expanded {
    .hidden-row {
      display: block;
      &:not(:empty) {
        padding-bottom: $card-spacer-x;
      }
    }
    > .horizontal {
      .preview-row {
        padding-bottom: $card-spacer-x;
      }
      .hidden-row {
        padding-bottom: 0;
      }
    }
    .expander-link {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}

// TODO: update arag-expander-card element to have the .card class added to the host element instead of it being nested
//       (i.e. it *is* a .card rather than containing a .card)
arag-expander-card:last-of-type {
  .card {
    border-radius: 0 0 $card-border-radius $card-border-radius;
  }
}

@include media-breakpoint-down(sm) { //fixed
  .horizontal {
    .preview-row {
      display: block;
    }
    .expander-link {
      display: inline-block;
      margin-top: $space-half;
    }
  }
}
