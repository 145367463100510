@import "../mixins/badges";

$arag-badge-types: (
  "primary": (
    "background": $color-arag-blue,
  ),
  "secondary": (
    "background": $body-color,
  ),
  "success": (
    "background": $color-utility-success-text
  ),
  "info": (
    "background": $color-utility-info-text
  ),
  "warning": (
    "background": $color-arag-yellow,
    "text": $color-crow
  ),
  "danger": (
    "background": $color-utility-danger
  ),
  "light": (
    "background": $color-swan,
    "text": $color-crow
  ),
  "dark": (
    "background": $color-crow
  )
) !default;

@each $type, $badge in $arag-badge-types {
  @include arag-badge-variant(
    $type,
    map-get($badge, "background"),
    map-get($badge, "text")
  );
}
