@import "../functions/buttons";

$color-scheme-dark-text-color: $color-swan !default;
$color-scheme-dark-link-color: $color-parakeet !default;
$color-scheme-dark-focus-inset-width: $input-btn-focus-width !default;
$color-scheme-dark-focus-highlight-color: $color-swan !default;
$color-scheme-dark-focus-highlight-width: 2px !default;

.background-day {
  background-color: $color-swan;
}

.background-sunset {
  background-color: $color-sheep;
}

.background-night {
  background-color: $color-beluga;
}

.color-scheme-dark {
  color: $color-scheme-dark-text-color;

  a {
    color: $color-scheme-dark-link-color;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $color-scheme-dark-text-color;
  }

  .btn-primary {
    &:hover,
    &.hover {
      border-color: $color-scheme-dark-focus-highlight-color;
    }

    &:focus,
    &.focus {
      border-color: arag-button-property("primary", "focus-border");
      outline-color: transparent;
      box-shadow: 0 0 0 $color-scheme-dark-focus-highlight-width $color-scheme-dark-focus-highlight-color, inset 0 0 0 $color-scheme-dark-focus-inset-width arag-button-property("primary", "focus-border");
    }
  }

  .btn-secondary {
    border-color: $color-scheme-dark-focus-highlight-color;

    &:focus,
    &.focus {
      border-color: arag-button-property("secondary", "focus-border");
      outline-color: transparent;
      box-shadow: 0 0 0 $color-scheme-dark-focus-highlight-width $color-scheme-dark-focus-highlight-color, inset 0 0 0 $color-scheme-dark-focus-inset-width arag-button-property("secondary", "focus-border");
    }
  }

  .btn-success {
    border-color: $color-scheme-dark-focus-highlight-color;

    &:focus,
    &.focus {
      border-color: arag-button-property("success", "focus-border");
      outline-color: transparent;
      box-shadow: 0 0 0 $color-scheme-dark-focus-highlight-width $color-scheme-dark-focus-highlight-color, inset 0 0 0 $color-scheme-dark-focus-inset-width arag-button-property("success", "focus-border");
    }
  }

  .btn-light {
    &:hover,
    &.hover {
      // not sure why, but focusing on a .btn-light.hover:focus messes with the border (found in color contrast utility docs)
      &:focus {
        border-color: arag-button-property("light", "focus-border");
      }
    }

    &:focus,
    &.focus {
      border-color: arag-button-property("light", "focus-border");
      outline-color: transparent;
      box-shadow: 0 0 0 $color-scheme-dark-focus-highlight-width $color-scheme-dark-focus-highlight-color, inset 0 0 0 $color-scheme-dark-focus-inset-width arag-button-property("light", "focus-border");
    }
  }

  .btn-danger {
    &:hover,
    &.hover {
      border-color: $color-scheme-dark-focus-highlight-color;
    }

    &:focus,
    &.focus {
      border-color: arag-button-property("danger", "focus-border");
      outline-color: transparent;
      box-shadow: 0 0 0 $color-scheme-dark-focus-highlight-width $color-scheme-dark-focus-highlight-color, inset 0 0 0 $color-scheme-dark-focus-inset-width arag-button-property("danger", "focus-border");
    }
  }

  .btn-link {
    &:not(:hover):not(.hover) {
      color: $color-scheme-dark-link-color;
    }

    &:focus,
    &.focus {
      color: $color-scheme-dark-link-color;
      outline-color: $color-scheme-dark-link-color;
    }
  }

  .btn-subtle {
    &:not(:hover):not(.hover) {
      color: $color-scheme-dark-text-color;
    }

    &:focus,
    &.focus {
      color: $color-scheme-dark-text-color;
      outline-color: $color-scheme-dark-text-color;
    }
  }

  .btn-implied {
    &:not(:hover):not(.hover) {
      color: $color-scheme-dark-text-color;
    }

    &:focus,
    &.focus {
      color: $color-scheme-dark-text-color;
      outline-color: $color-scheme-dark-text-color;
    }
  }

  .background-day,
  &.background-day {
    background-color: $color-arag-blue;
  }

  .background-sunset,
  &.background-sunset {
    background-color: $color-whale;
  }

  .background-night,
  &.background-night {
    background-color: $color-bowerbird;
  }
}
