@import "../variables/color-palette";
@import "../mixins/arag-icons";

.dropdown,
.dropup {
  display: inline-block;

  .dropdown-menu {
    padding: 0;
    &.show {
      width: auto;
      max-height: 350px;
      overflow: auto;
    }

    .dropdown-header {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      border-bottom: 1px solid $dropdown-border-color;
    }

    .dropdown-title {
      @include arag-font-size("large");
      color: $color-crow;
    }

    .dropdown-subtitle {
      @include arag-font-size("extra-small");
    }

    .dropdown-item {
      @include font-size("medium");
      color: #212529;
    }

    .dropdown-link {
      @include font-size($dropdown-font-size);  // ensure links are the same size as buttons
      color: $color-peacock;
      outline-offset: 0;
    }
  }

  .dropdown-toggle {
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    &::after {
      @include arag-icon("down");
      margin-left: $space-one;
      vertical-align: -.1rem;
      border: none;
    }

    &::before {
      margin-right: $space-quarter;
      font-weight: $font-weight-normal;
      color: $color-elephant;
      content: attr(dropdown-prefix-content);
    }

    &:focus {
      .btn-light {
        box-shadow: 0 0 0 2px $color-peacock;
      }

      .btn-primary {
        box-shadow: 0 0 0 2px $color-parakeet;
      }

      .btn-secondary {
        box-shadow: 0 0 0 2px $color-morpho;
      }

      .btn-danger {
        box-shadow: 0 0 0 2px #ee9a8d;
      }
    }

    &.dropdown-toggle-image {
      // there seems to be a margin on .dropdown-toggle::before whether we have a 'prefix' or not, so left margin is 0 here
      padding: $space-quarter $space-quarter $space-quarter 0;
      background-color: transparent;
      border: 0;

      &::after {
        margin-left: $space-half;
      }

      > img {
        width: 4.4rem;
        height: 4.4rem;
      }
    }
  }

  .dropdown-item:focus {
    color: $color-swan;
    background-color: $color-peacock;
    outline: 1px solid $color-peacock; // 'outline-style: auto;' seems to give user-agent an opportunity to apply an unwanted border-radius to buttons in the .dropdown-menu
  }

  &.dropdown-align-right .dropdown-menu {
    text-align: right;
  }
}

.profile-dropdown {
  .dropdown-header {
    display: flex;
    flex-direction: column-reverse;
  }

  .dropdown-link.logout {
    @include arag-icon-init($position: "right", $margin: $dropdown-item-padding-x);  // not actually the 'margin' we want, but it makes the padding math work

    &::after {
      @include arag-icon("sign-out");
      right: $dropdown-item-padding-x - $space-quarter;  // the 'md' icon size is 1.6rem wide in a 2.4rem box... need subtract half of that difference from right position so it will align properly
      padding: $space-three-quarters 0;
    }
  }
}
