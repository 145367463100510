$app-layout-menu-panel-width: 240px !default;

.layout-dashboard {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: $color-sheep;

  .content-panel {
    position: relative;
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    min-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $color-sheep;

    .content-body {
      padding: 3.2rem 2.4rem 6.4rem;
      @include media-breakpoint-up(lg) {
        max-width: 954px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 1140px;
      }
    }

    .content-large .content-body {
      @include media-breakpoint-up(lg) {
        max-width: 954px;
      }
    }

    .content-medium .content-body {
      @include media-breakpoint-up(md) {
        max-width: 644px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 768px;
      }
    }

    .content-small .content-body {
      @include media-breakpoint-up(md) {
        max-width: 520px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 582px;
      }
    }

    .content-extra-small .content-body {
      @include media-breakpoint-up(md) {
        max-width: 396px;
      }
    }
  }

  .header {
    .header-body {
      padding: 0;
      @include media-breakpoint-up(lg) {
        max-width: 954px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 1140px;
      }
    }

    .menu-toggle-button {
      flex: none;
      height: 100%;
      padding: .8rem;
      text-align: center;
      background: transparent;
      border: 2px solid transparent;
      border-right: 1px solid $color-koala;

      &:focus {
        border: 2px solid $color-peacock;
      }

      &:hover {
        background-color: $color-dove;
      }

      &:active {
        background-color: #cee5e9;
      }

      .label {
        font-size: 1rem;
        line-height: 1.6rem;
        color: $color-elephant;

        &::before {
          @extend %far;
          width: 100%;
          height: 3.2rem;
          margin-right: .8rem;
          font-size: 2.8rem;
          color: $color-arag-blue;
          text-align: center;
          vertical-align: middle;
          content: fa-content($fa-var-bars);
        }
      }
    }

    &.show-menu {
      .menu-toggle-button {
        // stylelint-disable selector-max-class
        .label::before {
          @include arag-icon("close");
          color: $color-ladybug;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .menu-drawer {
    flex: none;
    width: $app-layout-menu-panel-width;
    height: 100vh;
    margin-left: -$app-layout-menu-panel-width;
    overflow: hidden;
    transition: margin .4s;

    &.show-menu {
      margin-left: 0;
    }

    .menu-panel {
      position: fixed;
      width: $app-layout-menu-panel-width;
      max-width: 100%;
      height: 100%;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      background: linear-gradient(180deg, $color-arag-blue 0%, $color-bowerbird 100%);

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .menu-drawer {
      margin-left: 0;
    }
    .content-panel {
      min-width: auto;
    }
  }
}

.arag-menu-drawer {
  .drawer-header {
    flex-direction: row;
    padding: 0;
  }
  .menu-close-button {
    @include arag-font-size("micro");
    padding: $space-half;
    color: $color-swan;
    text-transform: uppercase;
    letter-spacing: .08rem;
    background-color: $color-iguana;
    border-right: 0;
    border-bottom: 0;
    &:focus {
      background-color: $color-bowerbird;
      outline-color: $color-swan;
    }
    &:hover{
      background-color: $color-bowerbird;
    }
    &:active {
      background-color: $color-whale;
    }
    .close-label {
      &::before {
        @extend %far;
        width: 100%;
        font-size: 2.8rem;
        line-height: $space-two;
        color: $color-swan;
        @include arag-icon("close");
      }
    }
  }

  .drawer.active {
    right: unset;
    left: 0;
    width: $app-layout-menu-panel-width;
    background: linear-gradient(180deg, $color-arag-blue 0%, $color-bowerbird 100%);
    .drawer-header {
      background: unset;
    }
    .drawer-content-container {
      padding: 0;
      background: unset;
    }
    .main-menu {
      margin-top: -$space-four;
    }
  }
}
