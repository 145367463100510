$app-layout-menu-panel-width: 240px !default;

.app-layout {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: $color-sheep;

  .menu-drawer {
    flex: none;
    width: $app-layout-menu-panel-width;
    height: 100vh;
    margin-left: -$app-layout-menu-panel-width;
    overflow: hidden;
    transition: margin .4s;

    &.show-menu {
      margin-left: 0;
    }

    .menu-panel {
      position: fixed;
      width: $app-layout-menu-panel-width;
      max-width: 100%;
      height: 100%;
      overflow-y: scroll;
      scrollbar-width: none;      /* Firefox */
      -ms-overflow-style: none;   /* Internet Explorer 10+ */
      background: linear-gradient(180deg, $color-arag-blue 0%, $color-bowerbird 100%);

      &::-webkit-scrollbar {      /* Webkit */
        width: 0;
        height: 0;
      }
    }
  }

  .content-panel {
    position: relative;
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    min-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $color-sheep;

    .content-body {
      padding: 3.2rem 2.4rem 6.4rem;
      @include media-breakpoint-up(md) {
        max-width: 520px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 582px;
      }
    }
  }

  .menu-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
    display: none;
    width: 100%;
    height: 100%;

    &.show-menu {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    .menu-drawer {
      margin-left: 0;
    }

    .menu-overlay {
      display: none;
    }

    .content-panel {
      min-width: auto;
    }
  }
}
