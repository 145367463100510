$color-notification-background: #fdf5c4 !default;
$color-notification-border: #bcdff1 !default;

.notification-container {
  padding: 1rem 1.5rem;
  font-weight: $headings-font-weight;
  color: $color-crow;
  background-color: $color-notification-background;
  border-color: $color-notification-border;
  transition: all .5s ease-in;
  .container {
    margin-top: $space-one-and-half;
    margin-bottom: $space-one-and-half;
  }
}

.notification-heading {
  font-size: 2rem;
  font-weight: $font-weight-normal;
}

.notification-message {
  @include arag-font-size("large");
}


.has-notification {
  .menu-drawer {
    height: unset;
    .menu-panel {
      position: static;
    }
  }
}

.notification-close {
  position: relative;
  top: -$space-one-and-half;
  right: -3rem;
  float: right;
  padding: $space-one-and-half 3rem;
  background-color: transparent;
  border: 0;
  border-radius: 2.5px;
  &:focus {
    color: $color-crow;
    border: solid $color-peacock .25rem;
    border-width: .25rem;
    outline: 0;
    box-shadow: 0 0 3px 1px $color-peacock;
    transition: all .2s ease-in-out;
  }
  .close-icon {
    @extend %far;
    font-size: 24px;
    color: $color-arag-pink;
    &::before {
      @include arag-icon("close");
    }
    &:hover {
      color: $color-crow;
    }
  }
}
