.page {
  display: flex;
  flex-flow: column nowrap;

  .skip-navigation {
    z-index: 1;
    width: 100%;
    height: $space-four;
    padding: $space-three-quarters 0;
    margin-top: -$space-four;
    text-align: center;
    background-color: $color-beluga;
    border-bottom: 2px solid $color-anchovy;
    transition: margin .3s;

    &:focus-within {
      position: fixed;
      margin-top: 0;

      ~ .layout-standard,
      ~ .layout-dashboard {
        margin-top: $space-four;
        transition: margin .3s;
        &.has-notification {
          margin-top: 0;
        }
      }

      ~ .arag-notification {
        margin-top: $space-four;
        transition: margin .3s;
      }

    }

    .skip-to-main-content-link {
      color: $color-peacock;
      border-color: $color-peacock;

      &:hover {
        color: $color-swan;
        background-color: $color-peacock;
      }
    }
  }
}
