.status-indicator {
  &.arag-icon-bg-circle {
    @include arag-icon-add($size: "xxl");
    @include arag-icon-add($size: "xl", $position: "right");
    $icon-size-properties: map-get($arag-icon-sizes, "xxl");
    $icon-box-size: map-get($icon-size-properties, "box-size");

    width: $icon-box-size + $space-three-quarters;
    height: $icon-box-size + $space-half;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    @each $color, $info in $arag-icon-colors {
      &.status-icon-color-#{$color} {
        &::after {
          color: map-get($info, "status-color");
        }
      }
    }
  }

  &.status-not-started {
    &::after {
      @include arag-icon("play-video");
      color: $color-arag-blue;
    }
  }

  &.status-in-progress {
    &::after {
      @include arag-icon("pause");
      color: $color-elephant;
    }
  }

  &.status-overdue {
    &::after {
      @include arag-icon("warning");
      color: $color-utility-danger-text;
    }
  }

  &.status-complete {
    &::after {
      @include arag-icon("success");
      color: $color-utility-success-text;
    }
  }
}

