// Brand colors...
$color-arag-yellow: #ffd400 !default;
$color-arag-blue: #00768d !default;
$color-arag-green: #6fb090 !default;
$color-arag-pink: #e34c84 !default;

// Gray colors...
$color-swan: #fff !default;
$color-sheep: #f6f9f9 !default;
$color-beluga: #f4f4f4 !default;
$color-anchovy: #eceded !default;
$color-dolphin: #dcdcdc !default;
$color-chinchilla: #c8c8c8 !default;
$color-koala: #949494 !default;
$color-elephant: #666 !default;
$color-crow: #2d2d2d !default;

// Accessory colors...
$color-dove: #e5f1f3 !default;
$color-shoebill: #d7e7ea !default;
$color-peacock: #0e69e9 !default;
$color-whale: #00637a !default;
$color-bowerbird: #005269 !default;
$color-parakeet: #fff160 !default;
$color-iguana: #1b8199 !default;
$color-copperhead: #6f673d !default;
$color-fox: #e1895b !default;

// Utility colors...
$color-ladybug: #de351b !default;
$color-cardinal: #bf2600 !default;
$color-frog: #129b71 !default;
$color-snake: #0d7763 !default;
$color-morpho: #4bb7e9 !default;
$color-bluejay: #227ab7 !default;
$color-raven: #002229 !default;

// Focus colors...
$color-navy-blue: #0e69e9 !default;
$color-sail: #a2d3eb !default;
$color-coral-candy: #f7cac3 !default;
$color-alligator: #06463a !default;

// Utility tokens...
$color-utility-danger: $color-ladybug !default;
$color-utility-danger-text: $color-cardinal !default;
$color-utility-info: $color-morpho !default;
$color-utility-info-text: $color-bluejay !default;
$color-utility-success: $color-frog !default;
$color-utility-success-text: $color-snake !default;
$color-utility-warning: $color-arag-yellow !default;
$color-utility-warning-text: $color-cardinal !default;

// Icon Color Options, used with the AragIconColor enum
$arag-icon-colors: (
  "primary": ("color": $color-arag-blue, "background": $color-dove, "status-color": $color-arag-blue),
  "secondary": ("color": $color-crow, "background": $color-anchovy, "status-color": $color-crow),
  "success": ("color": $color-utility-success-text, "background": #dcf6f1, "status-color": $color-utility-success-text),
  "info": ("color": $color-utility-info-text, "background": #dbeaff, "status-color": $color-utility-info-text),
  "tertiary": ("color": $color-swan, "background": #e1895b, "status-color": #e1895b),
  "warning": ("color": $color-copperhead, "background": $color-parakeet, "status-color": $color-copperhead),
  "danger": ("color": $color-utility-danger-text, "background": #f6eaed, "status-color": $color-utility-danger-text),
) !default;
