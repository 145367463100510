@import "../variables/color-palette";
@import "../variables/variables";
.modal {
  // ng-bootstrap modals add .d-block to show modal dialogs... adding that rule here do we don't have to pull
  // all 'display' utilities provided by Bootstrap.
  &.d-block {
    display: block;
  }

  .no-header {
    display: flex;
    flex-direction: column;
    .modal-body {
      order: 1;
    }
    .modal-footer {
      order: 2;
    }
  }

  .modal-header {
    .close {
      flex: 0 0 auto;
      padding: 2.4rem 1.2rem;
      margin: -2.4rem -1.2rem -2.4rem auto;
    }
    .btn-close {
      padding: .4rem 1.2rem;
      margin: 0;
    }
    align-items: flex-start;
  }

  .modal-footer {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: $modal-footer-padding-y $modal-footer-padding-x;
    background-color: $color-beluga;
  }
}
