// only lg and xl screens have fixed widths; smaller screens are always fluid
// see https://arag.frontify.com/document/240575#/layout/grids for details
.container {
  @include make-container();
  @include media-breakpoint-up(lg) {
    max-width: 954px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
}

.row {
  @include make-row();
}
