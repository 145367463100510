.layout-standard {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: $color-sheep;

  .header {
    width: 100%;
    padding: .9rem 0;

    .header-body {
      padding: 0;
    }

    .header-logo {
      width: 50px;
      height: 50px;
      margin-right: $space-three-quarters;
      margin-left: $space-three-quarters;
      overflow: hidden;
      background-image: url("../images/logos/arag-logo.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .content-panel {
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $color-sheep;

    main {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    .content-body {
      width: 100%;
      padding: 3.2rem 2.4rem 6.4rem;
      margin: 0 auto;
      @include media-breakpoint-up(md) {
        max-width: 520px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 990px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 1140px;
      }
    }

    .content-large .content-body {
      @include media-breakpoint-up(lg) {
        max-width: 954px;
      }
    }

    .content-medium .content-body {
      @include media-breakpoint-up(md) {
        max-width: 644px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 768px;
      }
    }

    .content-small .content-body {
      @include media-breakpoint-up(md) {
        max-width: 520px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 582px;
      }
    }

    .content-extra-small .content-body {
      @include media-breakpoint-up(md) {
        max-width: 396px;
      }
    }

    .footer-body {
      margin: 0 auto;
    }
  }
}
