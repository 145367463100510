.arag-accordion {
  .card {
    .accordion-heading {
      margin-bottom: 0;
    }
    .accordion-toggle-button {
      width: 100%;
      padding: $space-one-and-half $space-one-and-half;
      font-size: $h3-font-size;
      color: $color-arag-blue;
      text-align: left;
      background-color: $color-swan;
      border: 0;
      border-radius: $card-border-radius;
      .accordion-header {
        display: block;
        @include arag-icon-init("md", "right");
        &::after {
          color: $color-elephant;
          @include arag-icon("down");
        }
      }
      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-color: $color-peacock;
      }
    }

    .card-body {
      display: none;
    }

    &.open {
      .card-body {
        display: block;
      }

      .accordion-header {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}
