$rating-first-border-radius: 6.49px 0 0 6.49px !default;
$rating-last-border-radius: 0 6.49px 6.49px 0 !default;
$rating-color: $color-frog !default;

.arag-ratings-container {
  margin-bottom: $space-one;
  .ratings-header {
    margin-bottom: -.8rem;
  }
  .number-ratings {
    font-weight: $font-weight-medium;
  }
  .arag-ratings {
    .star {
      &.first {
        border-radius: $rating-first-border-radius;
        /* stylelint-disable-next-line selector-max-class */
        .half {
          border-radius: $rating-first-border-radius;
        }
      }
      &.last {
        border-radius: $rating-last-border-radius;
        /* stylelint-disable-next-line selector-max-class */
        .half {
          border-radius: $rating-last-border-radius;
        }
      }
      position: relative;
      display: inline-block;
      width: 2rem;
      height: 8px;
      margin: 0 .2rem;
      background-color: $color-dolphin;
    }
    .full {
      background-color: $rating-color;
    }
    .half {
      position: absolute;
      display: inline-block;
      height: 100%;
      overflow: hidden;
      background-color: $rating-color;
    }
  }
  .ratings-text {
    padding-left: $space-one;
    .rating-score {
      font-size: 25px;
      font-weight: $font-weight-medium;
      color: $color-arag-blue;
    }
    .rating-total {
      font-weight: $font-weight-medium;
    }
  }
}
