// The following list of imports were taken from "@fortawesome/fontawesome-pro/scss/fontawesome.scss" as of v5.10.0...

// Font Awesome variables are included in _variables.scss to ensure they're available to any app that may need them...
//@import "@fortawesome/fontawesome-pro/scss/variables";

// Font Awesome mixins and core are included in _mixins.scss to ensure they're available to any app that may need them...
//@import "@fortawesome/fontawesome-pro/scss/mixins";
//@import "@fortawesome/fontawesome-pro/scss/core";

@import "@fortawesome/fontawesome-pro/scss/sizing";
@import "@fortawesome/fontawesome-pro/scss/fixed-width";
@import "@fortawesome/fontawesome-pro/scss/list";
@import "@fortawesome/fontawesome-pro/scss/bordered-pulled";
@import "@fortawesome/fontawesome-pro/scss/animated";
@import "@fortawesome/fontawesome-pro/scss/rotated-flipped";
@import "@fortawesome/fontawesome-pro/scss/stacked";
@import "@fortawesome/fontawesome-pro/scss/icons";
@import "@fortawesome/fontawesome-pro/scss/screen-reader";

// Import Font Awesome custom web fonts
// NOTE:  When upgrading FontAwesome, be sure to compare the base classes within each of these
//        files against each corresponding placeholder class in _arag-icons.scss.
@import "@fortawesome/fontawesome-pro/scss/brands";
//@import "@fortawesome/fontawesome-pro/scss/light";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/sharp-solid";

// Duotone icons significantly increase the size of the generated CSS files... removing until we actually NEED them.
//@import "@fortawesome/fontawesome-pro/scss/duotone";
//

@import "../variables/variables";
@import "../mixins/arag-icons";

// WARNING: This is somewhat experimental. We'd like to get to a point where we simply add .arag-icon and .arag-icon-{whatever}
// to an element (or use an Angular directive to add them) and have everything work without having to spin through all of the
// registered icons and repeat all of the icon-related CSS for each element/component that needs to use icons.
.arag-icon {
  &:not(.arag-icon-pos-right) {
    @include arag-icon-add();

    @each $icon, $content in $arag-icons {
      &.arag-icon-#{$icon} {
        &::before {
          @include arag-icon($icon);
        }
      }
    }
  }

  &.arag-icon-pos-right {
    @include arag-icon-add($position: "right");

    @each $icon, $content in $arag-icons {
      &.arag-icon-#{$icon} {
        &::after {
          @include arag-icon($icon);
        }
      }
    }
  }
  &.card-body {
    // defaults for card-body icons, overriden by size down below if sent in
    @include arag-icon-add($size: "xxl");
    $icon-size-properties: map-get($arag-icon-sizes, "xxl");
    $icon-box-size: map-get($icon-size-properties, "box-size");
    min-height: $card-spacer-y + $icon-box-size;
    padding-left: $card-spacer-x + $icon-box-size - $space-one;
    &.arag-icon-bg-circle {
      min-height: $card-spacer-y * 2 + $icon-box-size;
      padding-left: $card-spacer-x + $icon-box-size + $space-one;
      &::before {
        left: $space-one-and-half;
      }
    }
    &::before {
      position: absolute;
      top: $card-spacer-y;
      left: map-get($icon-size-properties, "card-left");
      padding-top: $space-quarter;
      padding-bottom: $space-quarter;
      color: $color-arag-blue;
    }
  }
  &.arag-icon-bg-circle {
    &::before {
      $icon-size-properties: map-get($arag-icon-sizes, "xxl");
      $box-size: map-get($icon-size-properties, "box-size");
      $icon-size: map-get($icon-size-properties, "icon-size");
      padding-top: calc(($box-size - $icon-size) / 2);
      padding-bottom: calc(($box-size - $icon-size) / 2);
      color: $color-arag-blue;
      background-color: $color-dove;
      border-radius: 50%;
    }
  }
  &.arag-icon-bg-square {
    @include arag-icon-add($size: "lg");
    &::before {
      margin-right: $space-one;
      line-height: inherit;
      color: $color-arag-blue;
      background-color: $color-dove;
      border-radius: 4px;
    }
  }
  @each $size, $info in $arag-icon-sizes {
    &.arag-icon-size-#{$size} {
      @include arag-icon-add($size: $size);
      &.card-body {
        $icon-size-properties: map-get($arag-icon-sizes, $size);
        $icon-box-size: map-get($icon-size-properties, "box-size");
        // .card-body left padding + width of icon + icon margin
        min-height: $card-spacer-y + $icon-box-size;
        padding-left: $card-spacer-x + $icon-box-size + $space-one;
        // if xxl, check if bg-circle or not to handle different padding, having a :not is overriding padding rules in the cards.scss
        @if $size == "xxl" {
          padding-left: $card-spacer-x + $icon-box-size - $space-one;
          &.arag-icon-bg-circle {
            padding-left: $card-spacer-x + $icon-box-size + $space-one;
          }
        }
        &::before {
          $icon-size-properties: map-get($arag-icon-sizes, $size);
          $box-size: map-get($icon-size-properties, "box-size");
          $icon-size: map-get($icon-size-properties, "icon-size");
          top: map-get($icon-size-properties, "card-top");
          left: map-get($icon-size-properties, "card-left");
          padding-top: calc(($box-size - $icon-size) / 2);
          padding-bottom: calc(($box-size - $icon-size) / 2);
        }
      }
    }
    &.arag-icon-pos-right {
      @each $size, $info in $arag-icon-sizes {
        &.arag-icon-size-#{$size} {
          @include arag-icon-add($position: "right", $size: $size);
        }
      }
    }
  }
  @each $color, $info in $arag-icon-colors {
    &.arag-icon-color-#{$color} {
      &::before {
        color: map-get($info, "color");
        background-color: map-get($info, "background");
      }
    }
  }
}
