@import "../variables/color-palette";
@import "../mixins/font-size";
@import "../mixins/arag-icons";
@import "../mixins/arag-cards";

.action-card {
  .card-body {
    display: grid;
    grid-template-areas:
      "icon"
      "textarea"
      "cta";
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    row-gap: $space-three-quarters;
    overflow-x: auto;
    @include media-breakpoint-up(sm) {
      grid-template-areas: "icon textarea cta";
      grid-template-rows: auto;
      grid-template-columns: 10rem 5fr max-content;
    }
    &.arag-icon {
      &.arag-icon-bg-circle {
        padding-left: $card-spacer-x;
        &::before {
          position: relative;
          top: 0;
          left: 0;
          grid-area: icon;
        }
      }
    }
    arag-status-indicator {
      grid-area: icon;
    }
    :not(arag-status-indicator):not(.card-cta) {
      grid-area: textarea;
      p:last-of-type {
        margin-bottom: $space-half;
      }
    }
    .card-cta {
      grid-area: cta;
      align-content: center;
      margin-top: $space-one;
      @include media-breakpoint-up(sm) {
        justify-self: end;
        padding-left: $space-three-quarters;
        margin-top: 0;
      }
    }
  }
}

//  TODO: Are we ready to delete this?
.card-action {
  @include card-action();
  &:focus:not(:focus-visible) {
    outline: 2px auto $color-peacock;
  }
  .card-body {
    @include card-icon-body($icon: "right", $iconAlign: "right", $iconVerticalAlign: "middle");

    &::after {
      // stylelint-disable-next-line declaration-no-important
      display: flex !important;  // this is yucky but fontawesome *really* wants icon before/after to be inline-block
      color: $color-peacock;
    }

    .card-title {
      @include arag-font-size("medium");
      margin-bottom: 0;
      color: $color-peacock;
    }

    .card-text {
      @include arag-font-size("small");
    }
  }
}
