@import "arag-icons";

@mixin arag-button-variant($type, $background, $border: $background, $hover-background: $background, $hover-border: $hover-background, $active-background: $background, $active-border: $active-background, $focus-background: $background, $focus-border: $focus-background, $color) {
  @if ($border == null) {
    $border: $background;
  }
  @if ($hover-background == null) {
    $hover-background: $background;
  }
  @if ($hover-border == null) {
    $hover-border: $hover-background;
  }
  @if ($active-background == null) {
    $active-background: $background;
  }
  @if ($active-border == null) {
    $active-border: $active-background;
  }
  @if ($focus-background == null) {
    $focus-background: $background;
  }
  @if ($focus-border == null) {
    $focus-border: $focus-background;
  }
  @if ($color == null) {
    $color: $color-crow;
  }
  .btn-#{$type} {
    @include button-variant($background, $border, $color, $hover-background, $hover-border, $color, $active-background, $active-border);

    &.hover {
      background-color: $hover-background;
      border-color: $hover-border;
    }

    &.focus,
    &:focus,
    &:focus:not(:focus-visible){
      background-color: $focus-background;
      border-color: $focus-background;
      outline: $input-btn-focus-width solid $focus-border;
    }
  }
}

@mixin btn-icon-style-and-lookup($icon-name, $icon-position) {
  padding-right: 1.6rem;
  padding-left: 1.6rem;

  @if ($icon-position == "left") {
    &::before {
      @include arag-icon($icon-name);
      width: 2.4rem;
      margin-right: .8rem;
      margin-left: -.8rem;
    }

    &.btn-lg {
      &::before {
        width: 2.8rem;
      }
    }

    &.btn-sm {
      padding-right: 1.2rem;
      padding-left: 1.2rem;

      &::before {
        width: 2rem;
        margin-right: .4rem;
        margin-left: -.8rem;
      }
    }
  }
  @else if ($icon-position == "right") {
    &::after {
      @include arag-icon($icon-name);
      width: 2.4rem;
      margin-right: -.8rem;
      margin-left: .8rem;
    }

    &.btn-lg {
      &::after {
        width: 2.8rem;
      }
    }

    &.btn-sm {
      padding-right: 1.2rem;
      padding-left: 1.2rem;

      &::after {
        width: 2rem;
        margin-right: -.8rem;
        margin-left: .4rem;
      }
    }
  }
}
