.form {
  .form-row {
    @include make-row(calc($grid-gutter-width / 2));
    > [class*="col-"] {
      padding-right: .5rem;
      padding-left: .5rem;
    }
  }

  .form-group {
    display: block;

    label:not(.form-check-label),
    .label:not(.form-check-label) {
      margin-bottom: .8rem;
      font-weight: $font-weight-normal;
    }

    @include media-breakpoint-down(sm) {
      label:not(.form-check-label),
      .label:not(.form-check-label) {
        display: block;
      }
    }

    .label-container {
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-down(sm) {
      .label-container {
        display: block;
        margin-bottom: .8rem;
      }
    }

    .form-control + .help-text {
      margin-top: .8rem;
    }

    .help-text {
      margin-top: .4rem;
      color: $color-elephant;
    }
    .character-count-help-text {
      font-weight: $font-weight-bold;
    }
    // logic to determine if we have at least two help-text pieces and if we do apply the inline styling for display on the input field
    // this means they have both helper text and the character count helper text both.
    .help-text:nth-last-child(n+2),
    .help-text:nth-last-child(n+2) ~ .help-text {
      @include media-breakpoint-up(md) {
        display: inline-flex;
        width: 50%;
        &:first-child {
          padding-right: $space-one;
        }
        &:last-child {
          justify-content: end;
          padding-left: $space-one;
        }
      }
    }

    .error {
      margin-top: .8rem;
      color: $color-cardinal;
    }

    .form-select {
      display: inline-block;
      height: $form-select-height;
      font-size: $form-select-font-size;
      &:hover {
        background-color: $color-dove;

        &:active {
          background-color: $color-shoebill;
        }
        &:focus::-ms-value {
          background-color: $color-dove;
        }
      }

      option {
        background-color: $color-swan;

        &:disabled {
          background-color: $form-select-disabled-bg;
          border-color: $form-select-disabled-border-color;
        }

        &:active {
          background-color: $color-peacock;
        }
      }

      // if the .form-select is 'readonly', prevent the options from being shown/changed
      &[readonly] {
        pointer-events: none;
        option {
          display: none;
        }
      }
    }

    textarea {
      height: 9.6rem;
    }
  }

  .form-button-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.form-group {
  /* Don't require .label-container when there isn't anything but a label/.label... */
  > label:not(.form-check-label),
  > .label:not(.form-check-label) {
    display: block;
  }
  margin-bottom: $form-group-margin-bottom;
}

.required > label:not(.form-check-label),
label.required:not(.form-check-label),
.required > .label:not(.form-check-label),
.label.required:not(.form-check-label),
p.required:not(.form-check-label) {
  &::before {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    color: $color-arag-pink;
    text-align: center;
    content: "*";
  }
}

/* stylelint-disable selector-max-class */

/* show invalid only if also touched and dirty */
.ng-touched.ng-dirty.ng-invalid > .form-group > .form-control,  // this selector is deprecated and should be removed w/ <arag-input>
.form-group.ng-touched.ng-dirty.ng-invalid .form-control {
  border-color: $color-ladybug;

  &:focus {
    box-shadow: 0 0 0 1px $color-ladybug;
  }
}

/* show valid only if also touched and dirty */
.ng-touched.ng-dirty.ng-valid > .form-group > .form-control,    // this selector is deprecated and should be remvoed w/ <arag-input>
.form-group.ng-touched.ng-dirty.ng-valid .form-control {
  border-color: $color-frog;

  &:focus {
    box-shadow: 0 0 0 1px $color-frog;
  }
}

/* stylelint-enable selector-max-class */
.form-check {
  position: relative;
  display: block;
  margin-bottom: .8rem;
  print-color-adjust: exact;

  label,
  .label {
    font-size: $input-font-size;
    text-transform: none;

    > .title {
      font-weight: $font-weight-bold;
      color: $color-arag-blue;
    }

    > .description {
      color: $body-color;
    }
  }
  .form-check-label {
    margin-bottom: 0;
  }

  .form-check-input {
    position: absolute;
    left: 0;
    margin: 0;

    // In v4 this was 50% of a 24px box which made it 12, now we have a 20px box with a 2px border, so 60% of 20 = 12
    background-size: 60% 60%;

    @media (forced-colors: active) and (prefers-color-scheme: light) {  // this translates to Windows 'high contrast white' mode
      &:checked {
        background-image: escape-svg($form-check-input-checked-bg-image-high-contrast-light);
      }
    }

    // when focused and checked, the checkbox has a different border than other controls
    &:focus {
      border-color: $form-check-input-focus-border;
      outline: $focus-indicator-width solid transparent;
    }

    // For checkboxes we want the frog border/checked border when there is a focus,
    // the card will have the focus border color instead of the icon
    &:focus:checked {
      border-color: $color-alligator;
    }

    // when focused and not checked, the checkbox has the default focus border color
    &:focus:not(:checked) {
      border-color: $input-focus-border-color;
      outline: $focus-indicator-width solid transparent;
    }

    // 'readonly' is a gray area with checkboxes...
    &[readonly] {
      background-color: $form-check-input-disabled-bg;
      border-color: $form-check-input-disabled-bg;
    }

    // 'readonly' and focused should follow focus unchecked border/outline
    &[readlonly]:focus,
    &[readlonly]:focus:checked {
      border-color: $input-focus-border-color;
      outline: $focus-indicator-width solid transparent;
    }

    // We will  have to revisit how forms validate, in the previous version the label was marked as .invalid
    // I think the control itself shoudl be marked as .invalid so this should work, but we'll  have to find an example
    &.invalid {
      border-color: $color-ladybug;
    }

    &:disabled {
      &:checked {
        @include gradient-bg($form-check-input-disabled-bg);
      }
      &:indeterminate {
        @include gradient-bg($form-check-input-disabled-bg);
      }
    }

  }

  .form-check-input[type="radio"] {
    // when checked but not focused, the radio button has the default border color
    &:checked {
      background-color: $form-check-input-checked-color;
      border-color: $input-border-color;
      outline: $focus-indicator-width solid transparent;
    }

    // when focused, the radio button border is the same color as the center (icon) color
    &:focus {
      border-color: $custom-radio-indicator-checked-color;
      outline: $focus-indicator-width solid transparent;
    }

    // For a radio we want the grey border on the radio icon when checked/focus
    &:focus:checked {
      border-color: $input-border-color;
    }

    &:active {
      filter: unset;
    }

    &[disabled] {
      pointer-events: none;
      background-color: $input-disabled-bg;
      border-color: $input-disabled-border-color;

      &:checked {
        background-color: $input-bg;
        background-image: $form-check-radio-disabled-checked-bg-image;
      }
    }

    // disabled card-style radios have a few details to tweak
    &.card-control[disabled] {
      ~ .form-check-label {
        background-color: $color-anchovy;
      }

      &:checked {
        ~ .form-check-label {
          color: $input-color;
          background-color: $color-dove;
        }
      }
    }
  }
}

.form-check-input.card-control {
  // style the label similar to a box
  top: $card-control-padding;
  left: $form-check-gutter;

  ~ .form-check-label {
    display: block;
    padding: $card-control-padding;
    padding-left: $form-check-gutter + $form-check-input-width + $card-control-padding;
    margin-left: -$form-check-gutter - $form-check-input-width;
    background-color: $input-bg;
    border: $card-border-width solid $card-border-color;
    border-radius: $card-border-radius;


    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }

  // make a border of the 'card' when focus, remove border and shadow from checkbox element.
  &:focus ~ .form-check-label,
  &:focus:not(:checked) ~ .form-check-label {
    border-color: $input-focus-border-color;
    box-shadow: $form-select-focus-box-shadow;

    &::before {
      border-color: $input-border-color;
      box-shadow: none;
    }
  }

  &:focus,
  &:focus:not(:checked) {
    border-color: $input-border-color;
    box-shadow: none;
  }

  // When checked we have a different base background color
  &:checked ~ .form-check-label {
    color: $color-arag-blue;
    background-color: $color-dove;
  }

  // Hover state
  &:hover ~ .form-check-label {
    background-color: $color-shoebill;
  }

  &:hover:not(:checked) ~ .form-check-label {
    background-color: $color-dove;
  }

  // Active/Press state

  &:active ~ .form-check-label {
    background-color: $color-beluga;
    border-color: $input-focus-border-color;
    box-shadow: $form-select-focus-box-shadow;
  }

  &:active:not(:checked) ~ .form-check-label {
    background-color: $color-shoebill;
  }
}
