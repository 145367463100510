.action-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: 0 .8rem;

  .action {
    padding: 0;
    margin: 0 $space-quarter;
    white-space: nowrap;  // don't let html icons separate from their labels

    a {
      font-size: 1.4rem;
      color: $color-crow;
    }

    // TODO: This probably doesn't belong in here, but leaving it for now...
    .phone-nav-link {
      .fa-phone {
        margin-right: .5rem;
      }
    }
  }
}
