@import "bootstrap/scss/breadcrumb";

.breadcrumb {
  padding: .75rem 1rem .75rem 0;
  margin-bottom: $space-two;
  background-color: inherit;

  // breadcrumbs are always a :last-child when generated by the <arag-navigation-menu> component... this overrides some
  // baseline list rules that are only getting applied due to the nesting nature of Angular components (e.g. <host-element><div class="generated-from-template">...</div></host-element>)
  &:last-child {
    margin-bottom: $space-two;
  }

  .breadcrumb-item {
    &::before {
      content: none;
    }
  }
  .menu-link {
    text-transform: capitalize;
    @include arag-font-size("small");
    &:not(.last) {
      @include arag-icon-init("sm", "right", $vertical-align: "middle", $margin: $space-quarter);
      padding-right: inherit;
      &::after {
        position: initial;
        display: inline-flex;
        color: $color-elephant;
        @include arag-icon("right");
      }
    }
    &.last {
      color: $color-elephant;
      text-decoration: none;
      cursor: default;
    }
  }
}
