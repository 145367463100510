$menu-link-color: $color-swan !default;
$menu-link-background: transparent !default;
$menu-link-icon-color: #99c4cd !default;
$menu-link-hover-color: $menu-link-color !default;
$menu-link-hover-background: $menu-link-background !default;
$menu-link-press-color: $menu-link-hover-color !default;
$menu-link-press-background: $menu-link-hover-background !default;

$menu-link-active-color: $color-parakeet !default;
$menu-link-active-background: $color-raven !default;
$menu-link-active-icon-color: $menu-link-active-color !default;
$menu-link-active-hover-color: $menu-link-active-color !default;
$menu-link-active-hover-background: $menu-link-active-background !default;

$submenu-link-color: $menu-link-color !default;
$submenu-link-background: $color-iguana !default;
$submenu-link-icon-color: $menu-link-icon-color !default;
$submenu-link-hover-color: $submenu-link-color !default;
$submenu-link-hover-background: $submenu-link-background !default;
$submenu-link-press-color: $submenu-link-hover-color !default;
$submenu-link-press-background: $submenu-link-hover-background !default;

$submenu-link-active-color: $menu-link-active-color !default;
$submenu-link-active-background: $menu-link-active-background !default;
$submenu-link-active-icon-color: $submenu-link-active-color !default;
$submenu-link-active-hover-color: $menu-link-active-hover-color !default;
$submenu-link-active-hover-background: $menu-link-active-hover-background !default;
$submenu-link-active-press-color: $submenu-link-active-hover-color !default;
$submenu-link-active-press-background: $submenu-link-active-hover-background !default;

.main-menu {
  display: block;
  height: 100%;
  color: $color-swan;

  .menu-header {
    padding-bottom: 2.4rem;
    margin: 0 1.6rem;
    font-weight: $font-weight-medium;
    text-align: center;
    border-bottom: 2px solid $color-arag-yellow;

    .menu-header-logo-link {
      &:focus {
        outline-color: $color-swan;
      }
    }

    .menu-header-logo {
      width: 48px;
      height: 48px;
      margin-top: 2.5rem;
    }

    .menu-heading {
      margin-top: 1.7rem;
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-swan;
    }

    .menu-subheading {
      font-size: 1.4rem;
      line-height: 2rem;
      color: $color-anchovy;
      letter-spacing: .8px;
    }
  }

  .third-party-container {
    height: 60px;
    margin: 2rem 1.6rem;
    background: $menu-link-color;
    border-radius: 5px;

    .third-party-icon {
      width: 70%;
      height: 70%;
      margin: 1rem 15%;
      object-fit: scale-down;
    }
  }
}

.menu {
  padding-left: 0;
  margin: 2.4rem 0;
  list-style-type: none;

  .menu-link {
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem 0 1rem 1.6rem;
    font-size: 1.5rem;
    color: $menu-link-color;
    background-color: $menu-link-background;

    &:hover,
    &:focus {
      color: $menu-link-hover-color;
      text-decoration: none;
      background-color: $menu-link-hover-background;
      outline-color: $color-dove;
      // bring the focus indicator within the element to avoid overlap/clipping issues and 1px extra so a thin slice of
      // background acts as a border between the focused menu item and the main content area
      outline-offset: -$link-focus-indicator-width - 1px;
    }

    &:active {
      color: $menu-link-press-color;
      background-color: $menu-link-press-background;
    }

    &.external {
      .menu-link-label {
        &::after {
          @extend %far;
          position: absolute;
          right: 1.6rem;
          width: 2.4rem;
          height: 2.4rem;
          padding-top: .4rem;
          font-size: 1.6rem;
          color: $menu-link-icon-color;
          text-align: center;
          content: fa-content($fa-var-external-link);
        }
      }
    }

    &.active {
      color: $menu-link-active-color;
      background-color: $menu-link-active-background;

      .menu-link-icon {
        color: $menu-link-active-icon-color;
      }

      &:hover,
      &:focus {
        color: $menu-link-active-hover-color;
        background-color: $menu-link-active-hover-background;

        .menu-link-icon {
          color: $menu-link-active-hover-color;
        }
      }
    }
  }

  .menu-link-label {
    display: flex;
    align-items: baseline;

    .menu-link-icon {
      width: 2.4rem;
      min-width: 2.4rem;
      height: 2.4rem;
      font-size: 1.6rem;
      line-height: 1.5;
      color: $menu-link-icon-color;
      text-align: center;
    }

    .menu-link-text {
      padding-right: 4.8rem;
      padding-left: 1.6rem;
    }
  }

  .menu-toggle {
    .menu-link-label {
      &::after {
        @extend %fas;
        position: absolute;
        right: 1.6rem;
        width: 2.4rem;
        height: 2.4rem;
        padding-top: .4rem;
        font-size: 1.6rem;
        color: $menu-link-icon-color;
        text-align: center;
        content: fa-content($fa-var-chevron-up);
        transition: transform .35s;
      }
    }

    &.active {
      color: $menu-link-active-color;
      background-color: $menu-link-active-background;

      .menu-link-label::after {
        color: $menu-link-active-icon-color;
      }

      &:hover,
      &:focus {
        color: $menu-link-active-hover-color;
        background-color: $menu-link-active-hover-background;

        .menu-link-label::after {
          color: $menu-link-active-hover-color;
        }
      }
    }

    &.collapsed {
      .menu-link-label {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  // a submenu is a nested .menu with just a few changes...
  .menu {
    margin: 0;

    .menu-link {
      padding-top: .6rem;
      padding-bottom: .6rem;
      font-size: 1.4rem;
      line-height: 1;
      color: $submenu-link-color;
      background-color: $submenu-link-background;

      &:hover,
      &:focus {
        color: $submenu-link-hover-color;
        background-color: $submenu-link-hover-background;
      }

      &:active {
        color: $submenu-link-press-color;
        background-color: $submenu-link-press-background;
      }

      &.active {
        color: $submenu-link-active-color;
        background-color: $submenu-link-active-background;

        &:hover,
        &:focus {
          color: $submenu-link-active-hover-color;
          background-color: $submenu-link-active-hover-background;
        }

        &:active {
          color: $submenu-link-active-press-color;
          background-color: $submenu-link-active-press-background;
        }
      }
    }

    .menu-link-label {
      align-items: center;
    }
  }
}

.menu-header-extension {
  margin-top: $space-one;
}
