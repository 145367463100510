@import "../variables/variables";
@import "../variables/color-palette";

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  display: block;
  width: 100vw;
  height: 100vh;
  margin-right: -110%;
  overflow-y: auto;
  background-color: $color-swan;
  box-shadow: -1px 0 1px 0 $color-koala;
  transition: margin-right .33s ease-in-out;

  &.active {
    margin-right: 0;
  }

  &:not(.active) {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    &.drawer-large {
      width: $drawer-width-large;
    }

    &.drawer-medium {
      width: $drawer-width-medium;
    }

    &.drawer-small {
      width: $drawer-width-medium;
    }
  }

}

.drawer-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: $drawer-header-padding-y $drawer-header-padding-x;
  background-color: $color-beluga;
}

.drawer-content-container {
  padding: $drawer-content-padding-y $drawer-content-padding-x;
  background-color: $color-swan;
}

.drawer-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: $drawer-backdrop-bg;
  opacity: $drawer-backdrop-opacity;

  &.active {
    display: block;
  }
}

.prevent-scroll {
  // position: fixed;
  overflow: hidden;
}

