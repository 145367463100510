$drop-container-default-border-color: #979797 !default;

.arag-upload {
  @include make-col(12);
  @include media-breakpoint-up("sm") {
    @include make-col(10);
  }

  @include media-breakpoint-up("xl") {
    @include make-col(7);
  }
  .upload-label {
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(lg) { //fixed
      display: grid;
      margin-bottom: $space-half;
    }
  }

  .choose-file-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 85px;
    padding: $space-one-and-half $space-one-and-half;
    background-color: $color-swan;
    border: 2px $drop-container-default-border-color dashed;
    border-radius: $card-border-radius;
    &.active-drag {
      border-color: $color-peacock;
    }
    &.max-reached {
      border-color: $drop-container-default-border-color;
      border-style: solid;
    }
    @include arag-font-size("large");
    @include media-breakpoint-down(lg) { //fixed
      display: block;
      height: unset;
      margin-bottom: $space-half;
      text-align: center;
    }
  }

  @include media-breakpoint-down(lg) { //fixed
    .separator-text,
    .upload-container {
      padding-top: $space-one;
    }
  }

  .upload-text {
    @include arag-icon-init("xl", "left", $vertical-align: "middle", $margin: $space-two);
    &::before {
      font-size: 40px;
      @include arag-icon("upload");
    }
    &.max-reached-text {
      &::before {
        @include arag-icon("file-success");
        top: -10px;
      }
    }
  }

  .file-list {
    padding-inline-start: 0;
    list-style: none;
  }

  // TODO [AMICUS] Also for v1.7, fix this typo and convert file details to use CSS grid...
  .fiie-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $space-half;

    .file-name {
      @include arag-icon-init("md", "left", $vertical-align: "middle", $margin: $space-one);
      &::before {
        @include arag-icon("file");
      }
    }

    .file-info {
      display: flex;
      align-items: center;
    }

    .file-size {
      display: inline-block;
      margin-right: $space-one;
      line-height: 1;
    }

    .file-upload-delete-btn {
      @include arag-icon-init("md", "right", $vertical-align: "top", $margin: 0);
      width: 2.4rem;
      height: 2.4rem;
      padding: 0;
      background-color: transparent;
      border: 0;
      &::after {
        @include arag-icon("danger");
        color: $color-elephant;
      }
    }

    // TODO: [AMICUS] DEPRECATED... REMOVE IN v1.7
    .delete-link {
      color: $color-elephant;
      @include arag-icon-init("md", "right", $vertical-align: "middle", $margin: $space-one);
      &::after {
        @include arag-icon("danger");
      }
    }
  }

  .file-error {
    color: $color-ladybug;
  }
}
