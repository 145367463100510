// add default bottom margins and left padding to lists
ol,
ul,
dl {
  margin-bottom: $space-default;

  // let the container control all 'end' whitespace, otherwise we get margin + padding spacing...
  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  padding-left: 4rem;
}

ul.list {
  list-style: none;

  /* stylelint-disable selector-max-type */
  ul {
    list-style: disc;

    ul {
      list-style: circle;

      ul {
        list-style: square;
      }
    }
  }
  /* stylelint-enable selector-max-type */


  > li {
    &::before {
      @include arag-list-marker($list-item-marker-size, $list-item-marker-height, 0);
      @include arag-icon("list-bullet");
      color: $color-arag-blue;
      text-align: center;
    }
  }

  @include arag-list-size-variant("body-text-large", $list-item-marker-large-size, $list-item-marker-large-height, 0);
  @include arag-list-size-variant("body-text-small", $list-item-marker-small-size, $list-item-marker-small-height, 0);
  @include arag-list-size-variant("body-text-micro", $list-item-marker-micro-size, $list-item-marker-micro-height, 0);
}

ul.action-card-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;

  li {
    position: relative;

    &:first-child {
      .action {
        @include border-top-radius($list-group-border-radius);
      }
    }

    &:last-child {
      .action {
        margin-bottom: 0;
        @include border-bottom-radius($list-group-border-radius);
      }
    }
  }

  .action {
    position: relative;
    display: block;
    width: 100%;
    padding: $list-group-item-padding-y  $list-group-item-padding-x;
    padding-right: ($space-default + $list-group-item-padding-x * 2); // 'action' icon width + 'x' padding on each side of it
    margin-bottom: calc($list-group-border-width * -1);
    font-weight: $font-weight-normal;
    color: $list-group-color;
    text-align: left;
    background-color: $list-group-bg;
    border: $list-group-border-width solid $list-group-border-color;

    &::after {
      @include arag-icon("right");
      position: absolute;
      top: 50%;
      right: $list-group-item-padding-x;
      display: inline-block;
      width: $space-default;
      height: $space-default;
      padding-top: $space-quarter;
      text-align: center;
      transform: translateY(-50%);
    }

    &:hover {
      z-index: 2; // Place hover items above their siblings for proper border styling
      color: $list-group-action-hover-color;
      text-decoration: none;
      background-color: $list-group-hover-bg;
    }

    &:focus {
      z-index: 3; // Place focused items above all else
      outline: none;
      box-shadow: $list-group-action-focus-shadow;
    }

    &:active {
      color: $list-group-action-active-color;
      background-color: $list-group-action-active-bg;
    }

    &.active {
      color: $list-group-active-color;
      background-color: $list-group-active-bg;
      border-color: $list-group-active-border-color;

      &:hover {
        background-color: $list-group-action-active-hover-bg;
      }

      &:focus {
        box-shadow: $list-group-action-active-focus-shadow;
      }

      &:active {
        background-color: $list-group-action-active-active-bg;
      }

    }
  }
}

ol {

  > li {
    &::marker {
      color: $color-arag-blue;
    }
  }

  &.body-text-large > li::marker,
  li.body-text-large::marker {
    font-size: $list-item-marker-large-size;
  }

  &.body-text-small > li::marker,
  li.body-text-small::marker {
    font-size: $list-item-marker-small-size;
  }

  &.body-text-micro > li::marker,
  li.body-text-micro::marker {
    font-size: $list-item-marker-micro-size;
  }

  &.jumbo {
    padding-inline-start: 0;
    list-style: none;
    counter-reset: li;

    li {
      position: relative;
      margin-bottom: $space-default;
      counter-increment: li;

      &::before {
        display: block;
        width: 6rem;
        height: 6rem;
        padding-top: 1.5rem;
        margin-bottom: $space-three-quarters;
        margin-left: 0;
        font-size: 3rem;
        line-height: 1;
        color: $color-arag-blue;
        text-align: center;
        content: counter(li);
        background-color: $color-anchovy;
        border-radius: 50%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(sm) {
      padding-inline-start: 9rem;

      li {
        &::before {
          position: absolute;
          margin-right: $space-default;
          margin-bottom: 0;
          margin-left: -8.4rem;
        }
      }
    }
  }

  .list-item-title {
    @extend .h3;
    margin-bottom: $space-quarter;
  }

}
