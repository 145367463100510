.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: $color-swan;
  opacity: .9;
}

.spinner-container:not(.center-content) {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 40px;
  margin-top: -50px;
  text-align: center;
  @include media-breakpoint-down(sm) { //fixed
    margin-left: -60px;
  }
  .spinner-label {
    padding-left: 0;
    margin-left: -10px;
  }
}

.spinner-container.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.spinner-label {
  padding-left: 10px;
  margin-top: 25px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 60px;
  height: 60px;
  vertical-align: text-bottom;
  background-color: transparent;
  border: 10px solid $color-arag-blue;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}
