// This file contains variable definitions, including Bootstrap variable overrides...
@import "color-palette";
@import "../functions/spacing";

// -- FontAwesome Pro variables --
$fa-font-path: "../fonts/fa" !default;

// -- Bootstrap colors --
$primary:   $color-arag-yellow !default;
$secondary: $color-arag-blue !default;
$success:   $color-utility-success !default;
$info:      $color-utility-info !default;
$warning:   $color-utility-warning !default;
$danger:    $color-utility-danger !default;
$light:     $color-swan !default;
//$dark:      $gray-800 !default;


// -- ARAG fonts --
$font-family-sans-serif: "Calibri", "Arial", sans-serif !default;
$font-family-serif: "Georgia", "Times New Roman", "Times", serif !default;

$font-size-base: 1.6rem !default;
$font-size-body: 1.6rem !default;
$body-color: $color-elephant !default;

$font-size-large: 1.8rem !default;
$line-height-large: 2.8rem !default;

$font-size-medium: $font-size-base !default;
$line-height-medium: 2.4rem !default;

$font-size-small: 1.4rem !default;
$line-height-small: 2rem !default;

$font-size-extra-small: 1.2rem !default;
$line-height-extra-small: 1.8rem !default;

$font-size-micro: 1rem !default;
$line-height-micro: 1.6rem !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-boldest:         900 !default;
$font-weight-bolder:          bolder !default;

$h1-font-size: 3rem !default;
$h2-font-size: 2.4rem !default;
$h3-font-size: 2rem !default;
$h4-font-size: 1.8rem !default;
$h5-font-size: 1.6rem !default;
$h6-font-size: 1.4rem !default;

$display1-size: 4rem !default;
$display2-size: 3.6rem !default;
$display3-size: 2.8rem !default;
$display4-size: 2.4rem !default;

$display-font-weight: $font-weight-normal !default;
$display1-weight: $display-font-weight !default;
$display2-weight: $display-font-weight !default;
$display3-weight: $display-font-weight !default;
$display4-weight: $display-font-weight !default;

$display1-line-height: 4.8rem !default;
$display2-line-height: 4.4rem !default;
$display3-line-height: 3.6rem !default;
$display4-line-height: 2.8rem !default;

$headings-color: $color-arag-blue !default;

// spacing variables
$arag-spacing-sizes: (
  "quarter": .25,
  "half": .5,
  "three-quarters": .75,
  "one": 1,
  "one-and-half": 1.5,
  "default": 1.5,
  "two": 2,
  "three": 3,
  "four": 4,
  "eight": 8
) !default;

// we can't dynamically create scss variables, so this will have to do...
$space-quarter:        arag-spacing("quarter") !default;
$space-half:           arag-spacing("half") !default;
$space-three-quarters: arag-spacing("three-quarters") !default;
$space-one:            arag-spacing("one") !default;
$space-one-and-half:   arag-spacing("one-and-half") !default;
$space-default:        arag-spacing("default") !default;
$space-two:            arag-spacing("two") !default;
$space-three:          arag-spacing("three") !default;
$space-four:           arag-spacing("four") !default;
$space-eight:          arag-spacing("eight") !default;


// [impact analysis]
// - Search node_modules/bootstrap/scss for regex "[$](spacer|headings-margin-bottom|hr-margin-y|navbar-padding|nav-divider-margin-y|dropdown-divider-margin-y)"
// - all variable references should be highlighted by IntelliJ... any that are not need to be added to the regex and repeat the search
//
// Bootstrap $spacer variable...
//   directly referenced in:
//     _images.scss (.figure-img)
//     _tables.scss (.table)
//     _type.scss   (.blockquote)
//   influences values of:
//     $headings-margin-bottom  (h1-h6, .h1-.h6 in _reboot.scss and _type.scss)
//     $hr-margin-y (hr in _type.scss)
//     $navbar-padding-x,$navbar-padding-y (.navbar, .navbar-brand in _navbar.scss)
//     $nav-divider-margin-y (nav-divider in mixins/_nav-divider.scss used by _dropdown)

$spacer: $space-one !default;
$paragraph-margin-bottom: $space-default !default;

// shared component variables
$overlay-backdrop-bg: #000 !default;
$overlay-backdrop-opacity: .6 !default;

$focus-indicator-width: 2px !default;
$focus-indicator-color: $color-peacock !default;

// -- Bootstrap fonts --
$font-size-lg: $font-size-large !default;
$font-size-sm: $font-size-small !default;

// links
$link-color: $color-peacock !default;
$link-hover-color: $link-color !default;
$link-focus-indicator-width: $focus-indicator-width !default;
$link-focus-indicator-color: $focus-indicator-color !default;
$link-decoration: none;

// -- Bootstrap grid --
// disable default Bootstrap grid classes
$enable-grid-classes: false !default;

// adjust gutter to 24px instead of 30px (default)
$grid-gutter-width: 24px !default;

// only lg and xl have max widths; smaller devices are always fluid
$container-max-widths: (
  lg: 990px,
  xl: 1140px
) !default;


// -- Bootstrap color contrast settings --
$color-contrast-dark: $color-crow !default;
$color-contrast-light: $color-swan !default;

// -- Bootstrap typography changes --
$code-font-size: 1em;
//$code-color: $color-elephant;

// -- Bootstrap borders --
// most borders are $color-koala... variants will need to be manually specified.
$border-color: $color-koala !default;
$hr-border-color: $color-dolphin !default;

// set default border radius to 5px
$border-radius: 5px !default;
$border-radius-lg: $border-radius !default;
$border-radius-sm: $border-radius !default;

// -- Lists --
// The .action-card-list is based on Bootstrap's .list-group, so we'll piggyback on their variables, too...
$list-group-item-padding-x:         $space-three-quarters !default;
$list-group-item-padding-y:         $space-three-quarters !default;
$list-group-color:                  $color-elephant !default;
$list-group-border-color:           $border-color !default;

// these 'active' variables refer to the '.active' list item
$list-group-hover-bg:               $color-dove !default;
$list-group-active-color:           $color-swan !default;
$list-group-active-bg:              $color-arag-blue !default;
$list-group-active-border-color:    #979797 !default;

// the 'action' variables set link/button colors (our .action elements within the .action-card-list)
$list-group-action-color:           $list-group-color !default;
$list-group-action-hover-color:     $list-group-color !default;

// these 'active' variables refer to the ':active' state of the '.action' element
$list-group-action-active-color:    $list-group-color !default;
$list-group-action-active-bg:       #d7e7ea !default;

// these variables are our own...
$list-group-action-focus-shadow:        0 0 0 2px $color-peacock !default;  // .action:focus
$list-group-action-active-hover-bg:     $color-whale !default;              // .action.active:hover
$list-group-action-active-focus-shadow: 0 0 0 2px $color-morpho !default;   // .action.active:focus
$list-group-action-active-active-bg:    $color-bowerbird !default;          // .action.active:active

// these variables are used to control size/position of list item markers
$list-item-marker-large-size: $font-size-large !default;
$list-item-marker-large-height: $line-height-large !default;
$list-item-marker-large-margin: $space-half !default;

$list-item-marker-size: $font-size-medium !default;
$list-item-marker-height: $line-height-medium !default;
$list-item-marker-margin: $space-half !default;

$list-item-marker-small-size: $font-size-small !default;
$list-item-marker-small-height: $line-height-small !default;
$list-item-marker-small-margin: $space-half !default;

$list-item-marker-micro-size: $font-size-micro !default;
$list-item-marker-micro-height: $line-height-micro !default;
$list-item-marker-micro-margin: $space-half !default;


// -- Bootstrap form controls --

// This is the 'primary' theme color ($color-arag-yellow for us) by default and feeds into several other variables.
// Setting default component active color to $color-koala... $color-peacock was no bueno either...
$component-active-bg: $color-koala !default;

$form-group-margin-bottom: 2.4rem !default;

$input-font-size: $font-size-body !default;
// We have to use line-height factor because of bootstrap line height calculator
$input-line-height: 1.333 !default;
$input-height: 4.8rem !default;
$input-color: $color-elephant !default;
$input-placeholder-color: $color-koala !default;
$input-bg: $color-swan !default;
$input-disabled-bg: $color-dolphin !default;
$input-disabled-border-color: $color-koala !default;
// Form control borders/shadows (we use the box-shadow on focus to make the border thicker without causing size/shifting issues)
$input-border-width: 2px !default;
$input-border-color: $color-koala !default;
$input-border-radius: 4px !default;
$input-focus-border-color: $color-peacock !default;
$input-focus-box-shadow: 0 0 0 1px $input-focus-border-color !default;
$input-padding-y: .9rem !default;
$input-padding-x: .9rem !default;

// -- Bootstrap variables Checkboxes / Radios
// adjust space between radio/checkbox controls and their label
$form-check-gutter: 1.2rem !default; // This is  not a bootstrap variable, we'll see if we can get rid of it

// sets the size of the custom radio/checkbox indicator
$form-check-input-width: 2.4rem !default;
//$form-check-min-height:                   $font-size-base * $line-height-base;
$form-check-padding-start: $form-check-gutter + $form-check-input-width !default;
//$form-check-margin-bottom:                .125rem;
//$form-check-label-color:                  null;
//$form-check-label-cursor:                 null;
//$form-check-transition:                   null;

//$form-check-input-active-filter:          brightness(90%);

// -- Bootstrap variables for custom checkboxes --
//$form-check-input-bg:                     $input-bg;
$form-check-input-border: #adb5bd solid 2px;
$form-check-input-border-radius: 4px !default;
//$form-check-radio-border-radius:          50%;
$form-check-input-focus-border: $color-frog !default;
$form-check-input-focus-box-shadow: 0 0 0 4px $color-dove !default; // this sets the roundish background behind checkboxes and round background on radio buttons when focused

$form-check-input-checked-color: $color-swan !default;
$form-check-input-checked-bg-color: $color-frog !default;
//$form-check-input-checked-border-color:   $form-check-input-checked-bg-color;
// replace Bootstrap's checkmark with FontAwesome Pro's 'check' icon':
//   1. navigate to /node_modules/@fortawesome/fontawesome-pro/svgs
//   2. find the icon (grouped by 'family'... solid, light, etc.)
//   3. open the *.svg file
//   4. copy/paste 'viewBox' and 'd' values in the 'data:' url following Bootstrap's variable definition pattern
$form-check-input-checked-bg-image:                      str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='#{$form-check-input-checked-color}' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3e%3c/svg%3e"), "#", "%23") !default;
// NOT a bootstrap var
$form-check-input-checked-bg-image-high-contrast-light:  str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='currentColor' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3e%3c/svg%3e"), "#", "%23") !default;

// make the center of the radio button blue (Bootstrap assumes you want this to be the same as the checkbox color)
$custom-radio-indicator-checked-color: $color-peacock !default; // not a Bootstrap variable... defined here so we can control the radio button color separate from the checkbox icon color
$custom-radio-indicator-disabled-checked-color: $color-elephant !default; // not a Bootstrap variable... defined here so we can control the radio button color separate from the checkbox icon color
// copied this from Bootstrap's _variables.scss file so we can change the variable used for the fill color (e.g. $custom-radio-indicator-checked-color)
$form-check-radio-checked-bg-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='#{$custom-radio-indicator-checked-color}'/%3e%3c/svg%3e"), "#", "%23") !default;
$form-check-radio-disabled-checked-bg-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='#{$custom-radio-indicator-disabled-checked-color}'/%3e%3c/svg%3e"), "#", "%23") !default;

//$form-check-input-indeterminate-color:          $component-active-color;
//$form-check-input-indeterminate-bg-color:       $component-active-bg;
//$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color;
//$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity:        1;
$form-check-label-disabled-opacity:        $form-check-input-disabled-opacity;
//$form-check-btn-check-disabled-opacity:    $btn-disabled-opacity;

//$form-check-inline-margin-end:    1rem;


// these are built-in Bootstrap variables that we'll also use for 'readonly' checkboxes
$form-check-input-disabled-bg: $color-koala !default; // TODO: This no longer exists
$form-check-label-disabled-color: $input-color !default; // TODO: This no longer exists

// Padding around a control styled as a card
$card-control-padding: 1.6rem !default;

$form-select-padding-y:           $input-padding-y !default;
$form-select-padding-x:           $input-padding-x !default;
$form-select-font-size:             $input-font-size;
$form-select-indicator-padding:   $form-select-padding-x + 1.4rem !default; // Extra padding to account for the presence of the background-image based indicator
$form-select-focus-box-shadow: $input-focus-box-shadow !default;
$form-select-height: 4.8rem !default;
$form-select-color: $color-elephant !default;
$form-select-bg-size:             18px 20px !default; // In pixels because image dimensions
$form-select-indicator: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3e%3cpath fill='#{$color-elephant}' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3e%3c/svg%3e"), "#", "%23") !default;
$form-select-bg:  $form-select-indicator no-repeat right $form-select-padding-x center / $form-select-bg-size !default;
$form-select-border-radius:       $border-radius;
$table-cell-padding-x: .75rem;
$table-cell-padding-y: .75rem;

// --  Bootstrap buttons --
$btn-font-weight: $font-weight-bold !default;

// buttons have a larger border radius
$btn-border-radius: 24px !default;
$btn-border-radius-lg: 26px !default;
$btn-border-radius-sm: 16px !default;
$btn-border-width: 1px !default;

$input-btn-font-family: $font-family-sans-serif !default;
$input-btn-border-width: 1px !default;
$input-btn-focus-width: 3px !default;
$input-btn-focus-color: $focus-indicator-color !default;

$input-btn-padding-y-sm: .4rem !default;
$input-btn-padding-x-sm: 1.6rem !default;
$input-btn-font-size-sm: 1.2rem !default;
$input-btn-line-height-sm: 1.667 !default;

$input-btn-padding-y: .6rem !default;
$input-btn-padding-x: 2.4rem !default;
$input-btn-font-size: 1.6rem !default;
$input-btn-line-height: 1.5 !default;

$input-btn-padding-y-lg: .8rem !default;
$input-btn-padding-x-lg: 3.2rem !default;
$input-btn-font-size-lg: 1.8rem !default;
$input-btn-line-height-lg: 1.556 !default;

// -- Bootstrap card settings --
$card-bg: $color-swan !default;
$card-color: $body-color !default;
$card-border-width: 1px !default;
$card-border-radius: 4px !default;
$card-border-color: $color-koala !default;
$card-cap-bg: $color-anchovy !default;
$card-cap-color: $color-crow !default;
$card-title-color: $color-elephant !default;

// As of bootstrap 4 the following is used for padding on a card-body for x and y, and card header/footer for x
$card-spacer-x: 2.4rem !default;
// As of bootstrap 4 the following is used for padding a card header/footer y, it is not used for the card-body.
// See _cards.scss for y axis padding on a card body
$card-spacer-y: 2.4rem !default;

// -- Bootstrap modal settings --
// Modal Wrapper
$modal-dialog-margin:               2.4rem !default;
//$modal-dialog-margin-y-sm-up:       6.4rem !default;
// Actual modal
$modal-content-color: $body-color !default;
$modal-content-bg: $color-swan !default;
$modal-content-border-width: 1px !default;
$modal-content-border-color: $color-koala !default;
$modal-content-border-radius: 4px !default;
// Modal background
$modal-backdrop-bg: $overlay-backdrop-bg !default;
$modal-backdrop-opacity: $overlay-backdrop-opacity !default;
// Modal Header
$modal-header-padding-y: 2.4rem !default;
$modal-header-padding-x: 2.4rem !default;
$modal-header-border-width: 1px !default;
$modal-header-border-color: $color-koala !default;
// Modal Inner Content (Also used in footer but we override it)
$modal-inner-padding: 2.4rem !default;
// Footer (for actions)
$modal-footer-border-width: 1px !default;
$modal-footer-border-color: $color-beluga !default;
// Custom variables for modals
$modal-footer-padding-y: 1.6rem !default;
$modal-footer-padding-x: 1.2rem !default;

// -- Bootstrap dropdown settings --
$dropdown-box-shadow: none !default;
$dropdown-color: $body-color !default;
$dropdown-header-color: $dropdown-color !default;
$dropdown-item-padding-y: .8rem !default;
$dropdown-link-active-bg: $color-peacock !default;
$dropdown-link-hover-bg: $color-dove !default;

// -- Bootstrap Pagination settings --
$pagination-padding-y:              $space-half !default;
$pagination-padding-x:              $space-three-quarters !default;
$pagination-padding-y-sm:           $pagination-padding-y !default;
$pagination-padding-x-sm:           $pagination-padding-x !default;
$pagination-padding-y-lg:           $pagination-padding-y !default;
$pagination-padding-x-lg:           $pagination-padding-x !default;
$pagination-line-height:            2 !default;

$pagination-focus-box-shadow:       none !default;
$pagination-focus-width:            $input-btn-focus-width !default;
$pagination-focus-outline:          $pagination-focus-width solid $input-btn-focus-color !default;

$pagination-hover-bg:               $color-beluga !default;
$pagination-hover-border-color:     $pagination-hover-bg !default;

$pagination-active-color:           $color-swan !default;
$pagination-active-bg:              $color-arag-blue !default;
$pagination-active-border-color:    $color-koala !default;

$pagination-disabled-color:         $color-elephant !default;
$pagination-disabled-bg:            $color-swan !default;
$pagination-disabled-border-color:  #dee2e6 !default;
$pagination-border-color:           #dee2e6 !default;

// -- ARAG Component Settings --
// Drawer variables
$drawer-width-medium: 37.5rem !default;
$drawer-width-small: 37.5rem !default; // Not yet defined, when it is we can just change this vairable
$drawer-width-large: 37.5rem !default; // Not yet defined, when it is we can just change this vairable
// Drawer Overlay
$drawer-backdrop-bg: $overlay-backdrop-bg !default;
$drawer-backdrop-opacity: $overlay-backdrop-opacity !default;
// Drawer Header
$drawer-header-padding-y: 1.6rem !default;
$drawer-header-padding-x: 1.2rem !default;
// Drawer Content
$drawer-content-padding-y: 1.6rem !default;
$drawer-content-padding-x: 1.2rem !default;
// Tabs
$nav-link-font-weight: $font-weight-bold !default;
// TODO: [AMICUS] Next upgrade cycle, look at setting this variable for tab links rather than custom coding the color
//$nav-link-hover-color: $color-elephant !default;
