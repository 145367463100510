@import "../mixins/font-size";
@import "../variables/variables";
@import "../variables/color-palette";

@mixin arag-headline($headline-font-size:null,$headline-font-weight:null,$headline-line-height:null) {
  //you should only be calling this mixin for display-1 and h* styles defined in this file...
  // otherwise it could be misused and create pretty unpredictable results.
  font-size: $headline-font-size;
  font-weight: $headline-font-weight;
  line-height: $headline-line-height;
  color: $headings-color;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  font-family: $font-family-serif;
}

.display-1 {
  //for the sake of explicit clarity when compared against the other display-x styles below, we include this here
  //although ARAG's bootstrap variables default would compile to the same result for display-1
  @include arag-headline($display1-size,$display1-weight,$display1-line-height);
  margin-bottom: $space-one-and-half;
}

.display-2 {
  @include arag-headline($display2-size,$display2-weight,$display2-line-height);
  margin-bottom: $space-one-and-half;
}

.display-3 {
  @include arag-headline($display3-size,$display3-weight,$display3-line-height);
  margin-bottom: $space-one;
}

.display-4 {
  @include arag-headline($display4-size,$display4-weight,$display4-line-height);
  margin-bottom: $space-one;
}

@include media-breakpoint-only(xs) {
  .display-1 {
    @include arag-headline(3.2rem,$headline-line-height:3.6rem);
  }

  .display-2 {
    @include arag-headline(2.8rem,$headline-line-height:3.2rem);
  }

  .display-3 {
    @include arag-headline(2rem,$headline-line-height:2.4rem);
  }

  .display-4 {
    @include arag-headline(1.6rem,$headline-line-height:2rem);
  }
}


.display-large {
  @extend .display-1;
}

.display,
.display-medium {
  @extend .display-2;
}

.display-small {
  @extend .display-3;
}

.display-micro {
  @extend .display-4;
}

h1,
.h1 {
  @include arag-headline($h1-font-size,$headings-font-weight,3.6rem);
  margin-bottom: $space-one;
  &.arag-icon {
    &:not(.arag-icon-pos-right) {
      &::before {
        width: $h1-font-size;
        height: $h1-font-size;
        margin-right: $space-half;
        font-size: $h1-font-size;
      }
      &.arag-icon-bg-square {
        &::before {
          width: calc($h1-font-size + $space-one);
          height: calc($h1-font-size + $space-one);
          line-height: inherit;
        }
      }
    }
  }
}

h2,
.h2 {
  @include arag-headline($h2-font-size,$headings-font-weight,2.8rem);
  margin-bottom: $space-one;
  &.arag-icon {
    &:not(.arag-icon-pos-right) {
      &::before {
        width: $h2-font-size;
        height: $h2-font-size;
        margin-right: $space-half;
        font-size: $h2-font-size;
      }
      &.arag-icon-bg-square {
        &::before {
          width: 3.8rem;
          height: 3.8rem;
          line-height: inherit;
        }
      }
    }
  }
}

h3,
.h3 {
  @include arag-headline($h3-font-size,$headings-font-weight,2.4rem);
  margin-bottom: $space-three-quarters;
  &.arag-icon {
    &:not(.arag-icon-pos-right) {
      &::before {
        width: $h3-font-size;
        height: $h3-font-size;
        margin-right: $space-half;
        font-size: $h3-font-size;
      }
      &.arag-icon-bg-square {
        &::before {
          width: 3.4rem;
          height: 3.4rem;
          line-height: inherit;
        }
      }
    }
  }
}

h4,
.h4 {
  @include arag-headline($h4-font-size,$font-weight-bold,2.2rem);
  margin-bottom: $space-half;
  color: $color-crow;
  &.arag-icon {
    &:not(.arag-icon-pos-right) {
      &::before {
        width: $h4-font-size;
        height: $h4-font-size;
        margin-right: $space-half;
        font-size: $h4-font-size;
      }
      &.arag-icon-bg-square {
        &::before {
          width: 3.2rem;
          height: 3.2rem;
          line-height: inherit;
        }
      }
    }
  }
}

h5,
.h5 {
  @include arag-headline($h5-font-size,$font-weight-bold,2rem);
  margin-bottom: $space-half;
  color: $color-elephant;
  &.arag-icon {
    &:not(.arag-icon-pos-right) {
      &::before {
        width: $h5-font-size;
        height: $h5-font-size;
        margin-right: $space-half;
        font-size: $h5-font-size;
      }
      &.arag-icon-bg-square {
        &::before {
          width: 3rem;
          height: 3rem;
          line-height: inherit;
        }
      }
    }
  }
}

h6,
.h6 {
  @include arag-headline($h6-font-size,$font-weight-bold,1.8rem);
  margin-bottom: $space-half;
  color: $color-crow;
  text-transform: uppercase;
  letter-spacing: .08rem;
  &.arag-icon {
    &:not(.arag-icon-pos-right) {
      &::before {
        width: $h6-font-size;
        height: $h6-font-size;
        margin-right: $space-half;
        font-size: $h6-font-size;
      }
      &.arag-icon-bg-square {
        &::before {
          width: 2.7rem;
          height: 2.7rem;
          line-height: inherit;
        }
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  h1,
  .h1 {
    @include arag-headline(2.6rem,$headline-line-height:3.2rem);
    &.arag-icon {
      &:not(.arag-icon-pos-right) {
        &::before {
          width: 2.6rem;
          height: 2.6rem;
          font-size: 2.6rem;
        }
        &.arag-icon-bg-square {
          &::before {
            width: 4.2rem;
            height: 4.2rem;
            line-height: inherit;
          }
        }
      }
    }
  }

  h2,
  .h2 {
    @include arag-headline(2rem,$headline-line-height:2.4rem);
    &.arag-icon {
      &:not(.arag-icon-pos-right) {
        &::before {
          width: 2rem;
          height: 2rem;
          font-size: 2rem;
        }
        &.arag-icon-bg-square {
          &::before {
            width: 3.4rem;
            height: 3.4rem;
            line-height: inherit;
          }
        }
      }
    }
  }

  h3,
  .h3 {
    @include arag-headline(1.8rem,$headline-line-height: 2rem);
    &.arag-icon {
      &:not(.arag-icon-pos-right) {
        &::before {
          width: 1.8rem;
          height: 1.8rem;
          font-size: 1.8rem;
        }
        &.arag-icon-bg-square {
          &::before {
            width: 3.1rem;
            height: 3.1rem;
            line-height: inherit;
          }
        }
      }
    }
  }

  h4,
  .h4 {
    @include arag-headline(1.6rem,$headline-line-height:2rem);
    color: $color-crow;
    &.arag-icon {
      &:not(.arag-icon-pos-right) {
        &::before {
          width: 1.6rem;
          height: 1.6rem;
          font-size: 1.6rem;
        }
        &.arag-icon-bg-square {
          &::before {
            width: 3rem;
            height: 3rem;
            line-height: inherit;
          }
        }
      }
    }
  }

  h5,
  .h5 {
    @include arag-headline(1.4rem,$headline-line-height:1.8rem);
    color: $color-elephant;
    &.arag-icon {
      &:not(.arag-icon-pos-right) {
        &::before {
          width: 1.4rem;
          height: 1.4rem;
          font-size: 1.4rem;
        }
        &.arag-icon-bg-square {
          &::before {
            width: 2.8rem;
            height: 2.8rem;
            line-height: inherit;
          }
        }
      }
    }
  }

  h6,
  .h6 {
    @include arag-headline(1.2rem,$headline-line-height:1.6rem);
    color: $color-crow;
    &.arag-icon {
      &:not(.arag-icon-pos-right) {
        &::before {
          width: 1.2rem;
          height: 1.2rem;
          font-size: 1.2rem;
        }
        &.arag-icon-bg-square {
          &::before {
            width: 2.6rem;
            height: 2.6rem;
            line-height: inherit;
          }
        }
      }
    }
  }
}

// don't apply to checkbox/radio button labels...
label:not(.form-check-label),
.label:not(.form-check-label) {
  font-weight: $font-weight-bold;
  color: $color-crow;
  text-transform: uppercase;
  letter-spacing: .08rem;
}

/*
  Don't uppercase/space .label/label elements on long, question-style form labels:
    - .question is generally added to the .form-group for each field with long labels
    - .question.label is an alternative pattern on static label/value scenarios that don't have a form/form-group structure
 */
.question {
  label:not(.form-check-label),
  .label:not(.form-check-label),
  &.label {
    text-transform: none;
    letter-spacing: 0;
  }
}

label,
.label,
.label-medium {
  @include arag-font-size("medium");
}

.label-large {
  @include arag-font-size("large");
}

.label-small {
  @include arag-font-size("small");
}

.label-extra-small {
  @include arag-font-size("extra-small");
}

.label-micro {
  @include arag-font-size("micro");
}


.body-text-medium {
  @include arag-font-size("medium");
}

.body-text-large {
  @include arag-font-size("large");
}

.body-text-small {
  @include arag-font-size("small");
}

.body-text-extra-small {
  @include arag-font-size("extra-small");
}

.body-text-micro {
  @include arag-font-size("micro");
}

.body-text-bold {
  font-weight: $font-weight-bold;
}

// let the container control all 'end' whitespace, otherwise we get margin + padding spacing...
p:last-child {
  margin-bottom: 0;
}
