.arag-tab {
  .tab-content {
    margin-top: $space-one;
  }
  .nav-tabs {
    border-bottom-color: $color-koala;
    @include media-breakpoint-down(sm) { //fixed
      display: block;
    }
  }
  .tab-label {
    &.label-icon {
      @each $icon, $content in $arag-icons {
        &.label-icon-#{$icon} {
          @include arag-icon-init("md", "right", $vertical-align: "middle", $margin: $space-half);
          &::after {
            padding-right: $space-quarter;
            @include arag-icon($icon);
          }
        }
      }
    }
  }
  .nav-link {
    padding: $space-three-quarters;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    @include media-breakpoint-down(sm) { //fixed
      width: fit-content;
      margin-bottom: $space-half;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:hover {
      color: $color-elephant;
      text-decoration: none;
      background-color: $color-shoebill;
    }
    &.active {
      color: $color-elephant;
      border-color: $color-koala $color-koala $color-swan;
      @include media-breakpoint-down(sm) { //fixed
        width: fit-content;
        color: $color-swan;
        background-color: $color-arag-blue;
      }
    }
    .badge {
      display: inline;
      margin-left: $space-half;
    }
  }
  .nav-item {
    .btn-link {
      padding: .7rem;
      margin-top: .5rem;
      margin-left: $space-quarter;
    }
    @include media-breakpoint-down(sm) { //fixed
      margin-bottom: $space-half;
    }
  }
}
