.footer {
  width: 100%;
  margin-top: auto;  // any extra vertical space should come before the footer (i.e. force footer to bottom of content area when there's not enough content to fill the space)
  font-size: 1rem;
  background-color: $color-anchovy;

  .footer-body {
    padding: 3.2rem 2.4rem;
    @include media-breakpoint-up(md) {
      max-width: 520px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 990px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1140px;
    }
  }

  .footer-menu-bar {
    @include make-row();
    margin: 0;
    font-size: 1.4rem;

    .footer-menu {
      @include make-col(12);
      @include media-breakpoint-up("md") {
        @include make-col(8);
      }
    }

    .footer-copyright {
      @include make-col(12);
      @include media-breakpoint-up("md") {
        @include make-col(4);

        text-align: right;
      }
      margin-bottom: $space-one;
    }
  }

  .footer-badges {
    .nav {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      .footer-badges-list-item {
        padding: $space-three-quarters $space-two $space-three-quarters 0;
      }
      .footer-badge-image {
        height: 50px;
      }
    }
  }

  .footer-disclaimer {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  // TODO: Move this to a generic '_menu.scss' file?
  .menu-bar {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;

    .menu-item {
      padding: 0 2rem 0 0;
      margin-bottom: $space-one-and-half;
      a {
        padding: 0 .2rem;
        color: $color-elephant;
      }
    }
  }
}
