//stylelint-disable stylistic/value-list-comma-newline-after
//stylelint-disable stylistic/value-list-comma-space-after
@font-face {
  font-family: arag-icon;
  src: url("../fonts/arag/arag-icon.eot?t=1726506349132");                                // * IE9*
  src:
    url("../fonts/arag/arag-icon.eot?t=1726506349132#iefix") format("embedded-opentype"), // * IE6-IE8 *
    url("../fonts/arag/arag-icon.woff2?t=1726506349132") format("woff2"),
    url("../fonts/arag/arag-icon.woff?t=1726506349132") format("woff"),
    url("../fonts/arag/arag-icon.ttf?t=1726506349132") format("truetype"),                // * chrome, firefox, opera, Safari, Android, iOS 4.2+ *
    url("../fonts/arag/arag-icon.svg?t=1726506349132#arag-icon") format("svg");           // * iOS 4.1- *
}
//stylelint-enable stylistic/value-list-comma-newline-after
//stylelint-enable stylistic/value-list-comma-space-after
