.arag-table-container {
  .table-extension-container {
    display: block;
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .table-extensions {
      margin-bottom: $space-one-and-half;
    }
  }

  .header-link {
    color: $color-elephant;
  }
  .column-header {
    white-space: nowrap;
    @include arag-icon-init("md", "right", $vertical-align: "middle");
    &.sortable {
      &::after {
        @include arag-icon("sort");
        display: flex;
      }
    }
    &.sorted-asc {
      &::after {
        @include arag-icon("sort-up");
        display: flex;
      }
    }
    &.sorted-desc {
      &::after {
        @include arag-icon("sort-down");
        display: flex;
      }
    }
  }
  .pagination-container {
    margin-top: $space-one;
  }
}

.table {
  th {
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    border-bottom: calc(2 * $table-border-width) solid $table-border-color;
  }
}
