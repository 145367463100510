@keyframes ripple {
  100% {
    transform: translate(100%, 0);
  }
}

.skeleton {
  position: relative; // Ripple Effect
  overflow: hidden; // Ripple Effect
  pointer-events: none;
  cursor: default;
  user-select: none;
  // user-focus: none;

  .skeleton-profile-photo,
  .skeleton-image,
  .skeleton-headline,
  .skeleton-button {
    width: 100%;
    margin-bottom: $space-three-quarters;
    background-color: $color-chinchilla;
  }

  .skeleton-profile-photo {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
  }

  .skeleton-image {
    height: 8.4rem;
    border-radius: 8px;
  }

  .skeleton-headline {
    min-height: 4.4rem;
  }

  $skeleton-text-line-height: 2rem !default;
  $skeleton-text-line-space-between: $space-three-quarters !default;
  $skeleton-text-line-max: 3 !default;

  .skeleton-text-line {
    width: 100%;
    min-height: $skeleton-text-line-height;
    margin-bottom: $skeleton-text-line-space-between;
    background: linear-gradient(to bottom, $color-chinchilla percentage(calc($skeleton-text-line-height / ($skeleton-text-line-height + $skeleton-text-line-space-between))), transparent percentage(calc($skeleton-text-line-space-between / ($skeleton-text-line-height + $skeleton-text-line-space-between))));
    background-size: 100% ($skeleton-text-line-height + $skeleton-text-line-space-between);
  }

  @for $i from 1 through $skeleton-text-line-max {
    .skeleton-text-line-#{$i} {
      @extend .skeleton-text-line;
      min-height: (($skeleton-text-line-height + $skeleton-text-line-space-between) * $i) - $skeleton-text-line-space-between;
    }
  }

  .skeleton-button {
    width: 20rem;
    height: ($input-btn-font-size * $input-btn-line-height) + ($input-btn-padding-y * 2) + .2rem;  // $btn-border-width is in px so we can't math on it without unit conversion
    border-radius: $btn-border-radius;
  }

  // Ripple Effect
  &::after {
    position: absolute;
    top: 0;
    right: -150px;
    bottom: 0;
    left: -150px;
    content: "";
    background-image: linear-gradient(to right, transparent 0, $color-swan 75px, transparent 150px);
    opacity: 30%;
    animation-name: ripple;
    animation-duration: 1200ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
}

