.arag-datepicker {
  .input-group {
    &.ng-touched.ng-invalid {
      /* stylelint-disable-next-line selector-max-class */
      .btn-datepicker {
        border-top-color: $color-ladybug;
        border-right-color: $color-ladybug;
        border-bottom-color: $color-ladybug;
      }
    }
    &.ng-touched.ng-valid {
      /* stylelint-disable-next-line selector-max-class */
      .btn-datepicker {
        border-top-color: $color-frog;
        border-right-color: $color-frog;
        border-bottom-color: $color-frog;
      }
    }
  }
  .btn-datepicker {
    height: $space-three;
    margin: 0;
    background-color: $color-anchovy;
    border: 2px solid $color-koala;
    border-radius: 0 4px 4px 0;
    &::before {
      color: $color-crow;
      @include arag-icon("calendar");
      @include arag-font-size("large");
    }
    &:focus {
      box-shadow: 0 0 0 1px #0e69e9;
    }
  }
  .form-select {
    width: 100px;
    height: $space-three;
    padding: .9rem 2.3rem .9rem .9rem;
    margin: 0 $space-half;
    font-size: 1.6rem;
  }
  .ngb-dp-header {
    padding: $space-one;
    margin-bottom: 0;
    color: $card-cap-color;
    background-color: $card-cap-bg;
    &:first-child {
      @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
    }
    .ngb-dp-arrow-btn {
      margin: unset;
      &:focus {
        outline: unset;
      }
      &:disabled {
        color: $color-elephant;
      }
    }
  }
  .ngb-dp-weekdays {
    background-color: $card-cap-bg;
    border-bottom: $card-border-width solid $card-border-color;
  }
  .ngb-dp-month {
    flex: auto;
  }
  .ngb-dp-day {
    width: 100%;
    height: 100%;
    text-align: center;
    &:focus {
      outline: unset;
    }
  }
  .ngb-dp-weekday {
    width: 100%;
    font-style: normal;
    color: $color-arag-blue;
  }
  .custom-day {
    display: inline-block;
    padding: $space-half $space-one;
    font-size: $font-size-medium;
    font-weight: $font-weight-medium;
    color: $color-crow;
    border-radius: $btn-border-radius;
    &.outside {
      font-weight: $font-weight-normal;
      color: $color-elephant;
    }
    &.bg-primary {
      color: $color-swan;
      background-color: $color-peacock;
    }
    &.focused {
      box-shadow: 0 0 0 2px $color-peacock;
    }
    &.disabled {
      font-weight: $font-weight-normal;
      color: $color-elephant;
      text-decoration: line-through;
    }
    &:hover {
      color: $color-crow;
      background-color: $color-dove;
    }
  }
  .footer-button-container {
    display: flex;
    justify-content: space-between;
    padding: 0 $space-one $space-one $space-one;
  }
}
