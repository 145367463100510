@import "../variables/variables";

@mixin arag-font-size($size) {
  @if ($size == "large") {
    font-size: $font-size-large;
    line-height: $line-height-large;
  }
  @if ($size == "medium") {
    font-size: $font-size-medium;
    line-height: $line-height-medium;
  }
  @if ($size == "small") {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }
  @if ($size == "extra-small") {
    font-size: $font-size-extra-small;
    line-height: $line-height-extra-small;
  }
  @if ($size == "micro") {
    font-size: $font-size-micro;
    line-height: $line-height-micro;
  }
}
