@import "../variables";
@import "arag-icons";

@mixin card-action() {
  width: 100%;
  padding: $space-one;
  text-align: left;

  &:hover {
    text-decoration: none;
    background-color: $color-dove;
  }

  &:active {
    background-color: $color-shoebill;
  }

  &:focus {
    outline: 2px auto $color-peacock;
  }
}

@mixin card-icon-body($icon: null, $iconAlign: null, $iconVerticalAlign: null) {
  @if not $icon {
    padding: $card-spacer-y $card-spacer-x;
  }
  @else {
    $before-or-after: "before";
    @if $iconAlign == "right" {
      $before-or-after: "after";
    }
    @else {
      //stylelint-disable-next-line scss/dollar-variable-pattern
      $iconAlign: "left";
    }

    display: block;
    width: 100%;
    padding: 0;

    @include arag-icon-init($position: $iconAlign, $vertical-align: $iconVerticalAlign);

    &::#{$before-or-after} {
      @include arag-icon("right");
    }
  }
}
