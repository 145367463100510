@mixin arag-list-marker($list-item-marker-size: $space-one, $list-item-marker-height: $list-item-marker-size, $list-item-marker-margin: $space-three-quarters) {
  width: $list-item-marker-height;          // define square to place marker icon in
  height: $list-item-marker-size;           // define square to place marker icon in
  margin-right: $list-item-marker-margin;   // define gap between marker and text
  margin-left: -($list-item-marker-height + $list-item-marker-margin);  // position marker so text lines up
  font-size: $list-item-marker-size;        // set the size of the marker within the square
}

@mixin arag-list-size-variant($selector, $list-item-marker-size: $font-size-body, $list-item-marker-height: $list-item-marker-size, $list-item-marker-margin: $space-three-quarters ) {
  &.#{$selector} > li::before,
  li.#{$selector}::before {
    @include arag-list-marker($list-item-marker-size, $list-item-marker-height, $list-item-marker-margin);
  }
}
