@import "bootstrap/scss/mixins/border-radius";
@import "../mixins/arag-icons";
@import "../mixins/font-size";
@import "../variables/variables";

.card {
  margin-top: $space-three-quarters;
  margin-bottom: $space-three-quarters;
  text-align: left;

  &.card-danger {
    border: 2px solid $color-utility-danger;

    > .card-body,
    .card-title,
    .card-title.arag-icon::before {
      color: $color-utility-danger-text;
    }
  }

  &.card-success {
    border: 2px solid $color-utility-success;

    > .card-body,
    .card-title,
    .card-title.arag-icon::before {
      color: $color-utility-success-text;
    }
  }

  .card-text {
    font-weight: $font-weight-medium;
  }

  // TODO [AMICUS] For v1.7, fix this and convert card-body to start having it use CSS grid
  .card-body {
    &.card-body-no-text {
      .card-title {
        padding-top: 1.4rem;
      }
    }
  }

  &.vertical{
    text-align: center;

    .card-body {
      width: 100%;
      padding-left: $card-spacer-x;
      &::before {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: $space-one;
      }
      &.arag-icon {
        padding-left: $card-spacer-x;
        &::before {
          position: relative;
          top: 0;
          // stylelint-disable-next-line declaration-no-important
          left: 0 !important;
          height: 5rem;
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: $space-one;
        }
      }
      @each $size, $info in $arag-icon-sizes {
        &.arag-icon-size-#{$size} {
          $icon-size-properties: map-get($arag-icon-sizes, $size);
          padding-left: $card-spacer-x;
          &::before {
            @if $size == "xxl" {
              height: 5rem;
            } @else {
              height: map-get($icon-size-properties, "box-size");
            }
          }
          /* stylelint-disable-next-line selector-max-class */
          &.arag-icon-bg-circle {
            padding-left: $card-spacer-x;
          }
        }
        &.arag-icon-bg-circle {
          &::before {
            top: 0;
            left: 0;
            @if $size == "xxl" {
              $icon-size-properties: map-get($arag-icon-sizes, $size);
              $box-size: map-get($icon-size-properties, "box-size");
              $icon-size: map-get($icon-size-properties, "icon-size");
              height: $box-size;
              padding-top: calc(($box-size - $icon-size) / 2);
              padding-bottom: calc(($box-size - $icon-size) / 2);
            }
          }
        }
      }
    }
  }

  &.add-more {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: $space-one;
    border: 2px dashed $color-peacock;

    .card-body {
      display: inline-grid;
      grid-auto-rows: minmax(min-content, max-content);
      grid-auto-flow: row;
      align-content: center;
      align-self: center;
      min-height: 3.2rem;
      padding: 0;
      margin: $space-one 0;
      text-align: center;

      &::before {
        width: 100%;
        margin-top: 0;
        font-size: 3.2rem;
        color: $color-peacock;
        @include arag-icon("plus-circle");
      }

      .card-title {
        @include arag-font-size("medium");
        margin-top: $space-three-quarters;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        color: $color-peacock;
      }

      .card-text {
        @include arag-font-size("medium");
        margin-top: $space-quarter;
        font-weight: $font-weight-medium;
      }
    }

    &:hover {
      background-color: $color-beluga;
    }

    &:focus {
      color: $color-swan;
      background-color: $color-peacock;

      .card-title {
        color: $color-swan;
      }

      .card-body {
        color: $color-swan;
        &::before {
          color: $color-swan;
        }
      }
    }
  }
}

.card-title {
  @extend .h4;
  margin-bottom: 0;
  line-height: 2.4rem;
  color: $card-title-color;

  &.arag-icon::before {
    color: $body-color;
  }

  ~ .card-text {
    margin-top: $space-half;
  }
}

// .card-title inherits from .h4 which has $space-half bottom margin... add $space-quarter to the top of .card-subtitle
// to get the desired $space-three-quarters total between them.
.card-subtitle {
  margin-top: $space-quarter;
  color: $body-color;
}

.card-header {
  padding: $space-three-quarters $card-spacer-x;
  font-weight: $font-weight-bold;
  color: $card-cap-color;
  @include arag-font-size("medium");
  text-transform: uppercase;
  letter-spacing: .08rem;
  background-color: $card-cap-bg; // why am I needing to do this?

  // not sure why the additional ':not(.arag-icon-pos-right)' selector is needed for h1-h6, but because of that, we need it
  // here, too, to win the specificity battle...
  &.arag-icon:not(.arag-icon-pos-right):not(.arag-icon-bg-square) {
    $icon-size-properties: map-get($arag-icon-sizes, "md");
    $icon-box-size: map-get($icon-size-properties, "box-size");
    $font-size: map-get($icon-size-properties, "icon-size");

    position: relative;
    // .card-header left padding + width of icon
    padding-left: $card-spacer-x + $icon-box-size;

    // specify everything here to ensure we override the h1-h6 styles so the .card-header stays consistent regardless of the heading level
    &::before {
      position: absolute;
      top: $space-three-quarters;
      left: $card-spacer-x;
      width: $icon-box-size;
      height: $icon-box-size;
      margin-right: 0;
      font-size: $font-size;
      line-height: 1;
      color: $color-elephant;
    }
  }

  // If the heading is the last thing (likely the only thing) inside of the .card-header, remove the bottom margin to eliminate extra space
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.review-card {
  > .card-body {
    display: grid;
    grid-template-areas:
      "title action"
      "content content";
    grid-template-rows: auto;
    grid-template-columns: 1fr min-content;
    gap: $space-quarter;
    overflow-x: auto;

    .card-title {
      @extend .h3;
      grid-area: title;
      align-self: center;
      justify-self: start;
      margin-bottom: 0;
    }

    .btn-edit {
      grid-area: action;
      align-self: center;
      justify-self: end;
      white-space: nowrap;
    }

    .review-content {
      grid-area: content;
    }
  }
}

table.review-content,
.review-content > table {
  width: 100%;

  caption {
    caption-side: top;
  }

  th {
    width: 50%;
    padding: $space-three-quarters $space-quarter $space-three-quarters 0;
    font-weight: $font-weight-bold;
    vertical-align: top;

    @include media-breakpoint-up(md) {
      width: 33%;
    }
  }

  td {
    padding: $space-three-quarters 0 $space-three-quarters $space-quarter;
    vertical-align: top;
  }
}


// The '.card-stack' is based on Bootstrap's '.card-group', but groups the cards vertically
.card-stack {
  display: flex;
  flex-direction: column;
  margin-bottom: $card-group-margin;

  // The child selector allows nested '.card' within '.card-stack' to display properly.
  > .card {
    margin-top: 0;
    margin-bottom: 0;
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);

    + .card {
      border-top: 0;
    }

    // Handle rounded corners
    @if $enable-rounded {
      &:not(:last-child) {
        @include border-bottom-radius(0);

        .card-img-top,
        .card-header {
          @include border-top-radius(0);
        }
        .card-img-bottom,
        .card-footer {
          @include border-bottom-radius(0);
        }
      }

      &:not(:first-child) {
        @include border-top-radius(0);

        .card-img-top,
        .card-header {
          @include border-top-radius(0);
        }
        .card-img-bottom,
        .card-footer {
          @include border-bottom-radius(0);
        }
      }
    }
  }
}

.card-group {
  margin-bottom: $card-group-margin;

  > .card-header {
    display: flex;
    flex: 1 1 100%;

    // any .card elements that are siblings of the .card-group-header need some border tweaking
    ~ .card {
      @include border-bottom-start-radius($card-border-radius); //left and right radius is now start and end radius

      // this will undo the bottom left border for all but the first .card that is a sibling of the .card-group-header
      ~ .card {
        @include border-radius(0);
      }

      // set the bottom right radius of the last .card
      &:last-child {
        @include border-bottom-end-radius($card-border-radius);
      }
    }
  }

  > .card {
    margin-top: 0;
  }
}

.card-stack,
.card-group {
  > .card-header {
    border: $card-border-width solid $card-border-color;
    border-bottom: 0;
    @include border-radius($card-border-radius $card-border-radius 0 0);
  }
}

.card-footer-action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-top: $card-border-width solid $card-border-color;
  .card-footer-action {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: $space-quarter $space-half;
    &:not(:first-of-type) {
      border-left: $card-border-width solid $card-border-color;
    }
  }
}

.card-deck {
  .card {
    margin-bottom: 1.2rem;
  }
}

@include media-breakpoint-up(sm) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1.2rem;
    margin-left: -1.2rem;

    .card {
      flex: 1 0 0;
      margin-right: 1.2rem;
      margin-bottom: 0;
      margin-left: 1.2rem;
    }
  }
}
