.arag-stepper {
  margin: $space-one 0;
  .step-name {
    @include arag-font-size("large");
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: $font-weight-medium;
    color: $color-arag-blue;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .step-list {
    display: flex;
    justify-content: center;
    padding-inline-start: 0;
    .step-item {
      width: 100%;
      max-width: 36px;
      margin: 0 3px;
      list-style-type: none;
      @include media-breakpoint-up(lg) {
        max-width: 136px;
        margin: 0 10px;
      }
      .step {
        @include arag-font-size("medium");
        position: relative;
        width: 100%;
        height: 100%;
        padding-bottom: $space-one;
        text-align: center;
        border-bottom: $space-quarter solid $color-dolphin;
        /* stylelint-disable-next-line selector-max-class */
        &.current {
          font-weight: $font-weight-medium;
          color: $color-arag-blue;
          border-bottom-color: $color-arag-blue;
        }
        /* stylelint-disable-next-line selector-max-class */
        &.complete {
          border-bottom-color: $color-dolphin;
          /* stylelint-disable-next-line selector-max-class */
          &::after {
            position: absolute;
            bottom: -1rem;
            left: 50%;
            display: none;
            color: $color-arag-blue;
            background-color: $color-sheep;
            transform: translateX(-50%);
            @include arag-icon("success");
            @include media-breakpoint-up(lg) {
              display: block;
            }
          }
        }
      }
    }
    .step-text {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  &.arag-stepper-align-left {
    .step-name {
      justify-content: left;
    }
    .step-list {
      justify-content: left;
    }
  }
}
