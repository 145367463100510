.arag-validation-list-container {
  @include arag-font-size("extra-small");

  .validation-list {
    padding-inline-start: 0;
    margin: $space-one 0;
    column-count: 1;
    list-style: none;
    @include media-breakpoint-up(lg) {
      column-count: 2;
    }
  }

  .validation-list-item {
    @include arag-icon-init("sm", "left", $vertical-align: "middle", $margin: $space-half);
    display: inline-block;
    margin-bottom: $space-half;
    &::before {
      @include arag-font-size("medium");
      @include arag-icon("default-icon");
    }
    &.valid {
      @include arag-icon-init("sm", "left", $vertical-align: "middle", $margin: $space-half);
      color: $color-utility-success-text;
      &::before {
        @include arag-font-size("medium");
        @include arag-icon("success");
      }
    }
    &.invalid {
      @include arag-icon-init("sm", "left", $vertical-align: "middle", $margin: $space-half);
      color: $color-utility-danger-text;
      &::before {
        @include arag-font-size("medium");
        @include arag-icon("danger");
      }
    }
  }
}
