@import "../variables/color-palette";
@import "../mixins/arag-icons";
@import "bootstrap/scss/mixins/breakpoints";


.alert {
  display: block;
  max-width: 93.6rem;
  padding: 1.6rem 1.2rem;
  margin-top: $space-default;
  margin-bottom: $space-default;
  background-color: $color-swan;
  border: 2px solid $color-elephant;
  border-radius: 12px;

  .alert-body {
    position: relative;
    display: block;
    max-width: 68rem;
    margin: 0 0 0 4.8rem;

    &::before {
      position: absolute;
      top: 0;
      left: -4.8rem;
      display: inline-block;
      width: 3.2rem;
      height: 3.2rem;
      font-size: 2.8rem;
      font-weight: $font-weight-boldest;
      text-align: center;
      vertical-align: middle;
    }

    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }

  &.alert-danger {
    color: $color-utility-danger-text;
    border-color: $color-utility-danger;

    .alert-body::before {
      color: $color-utility-danger;
      @include arag-icon("danger");
    }
  }

  &.alert-warning {
    color: $color-utility-warning-text;
    border-color: $color-utility-warning;

    .alert-body::before {
      color: $color-utility-warning;
      @include arag-icon("warning");
    }
  }

  &.alert-info {
    color: $color-utility-info-text;
    border-color: $color-utility-info;

    .alert-body::before {
      color: $color-utility-info;
      @include arag-icon("info");
    }
  }

  &.alert-success {
    color: $color-utility-success-text;
    border-color: $color-utility-success;

    .alert-body::before {
      color: $color-utility-success;
      @include arag-icon("success");
    }
  }
}
