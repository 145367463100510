.arag-pagination {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  .pager {
    display: flex;
    justify-content: center;
    order: 1;
    width: 100%;
    &.with-page-size-selection {
      justify-content: space-between;
    }

    @include media-breakpoint-down(sm) { //fixed
      display: block;
      .rows-per-page {
        padding-bottom: $space-one-and-half;
      }
    }

    .page-link {
      &:focus {
        // the .active link has z-index: 3... we want focus outline to be on top
        z-index: 4;
        outline-offset: -$pagination-focus-width;
      }

      .first::after {
        @include arag-icon("first");
      }
      .previous::after {
        @include arag-icon("left");
      }
      .next::after {
        @include arag-icon("right");
      }
      .last::after {
        @include arag-icon("last");
      }
      line-height: 3.2rem;
    }

    @include media-breakpoint-down(sm) { //fixed
      .hide-mobile {
        display: none;
      }
    }
  }

  .page-item.active .page-link:focus {
    outline-color: $pagination-active-color;          // use the font color to ensure acceptable contrast ratio
    outline-offset: -$pagination-focus-width - 2px;   // offset an additional 2px to inset the outline a little more to let background act as a border
  }

  .result-description {
    display: flex;
    justify-content: center;
    order: 2;
    width: 100%;
    padding-top: $space-three-quarters;
    @include arag-font-size("large");
    &.with-page-size-selection {
      justify-content: flex-end;
      @include media-breakpoint-down(sm) { //fixed
        justify-content: flex-start;
      }
    }
  }
}
