.header {
  width: 100vw;
  background-color: $color-swan;
  border-bottom: 4px solid $color-anchovy;

  // force buttons in the header to be small
  .btn,
  .btn.btn-lg,
  .btn.btn-sm {
    @extend .btn-sm;
  }

  // semi-dynamic, 3-column grid
  //   left (logo): 'auto' --> size it to fit the logo and/or menu button
  //   middle: 'minmax(37%, 1fr)' --> make center a minimum of 37% of overall width to force right column to start giving up its space sooner; all extra space goes to the middle
  //   right: 'minmax(min(50px, 100%), max-content)' --> right column is a minimum of 50px (same as logo) or 100% of its width (to prevent overflow if an element is placed there that's less than 50px) and no larger than necessary to fit the content
  .header-body {
    display: grid;
    grid-template-columns: auto minmax(37%, 1fr) minmax(min(50px, 100%), max-content);
    gap: $space-quarter;
    align-items: center;
    justify-content: space-between;

    .header-area-left {
      grid-column: 1;
    }

    .header-area-right {
      grid-column: 3;
    }
  }
}
