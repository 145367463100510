.tooltip-inner {
  @include arag-font-size("extra-small");
  font-weight: $font-weight-medium;
  background-color: $color-crow;
}

.bs-tooltip-left,
.bs-tooltip-right {
  .tooltip-inner {
    text-align: left;
  }
}
