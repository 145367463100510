.form-control {
  height: $input-height;

  &.form-check-input {
    height: 2.4rem;
  }

  &.ng-touched {
    &.ng-invalid {
      border-color: $color-ladybug;
    }

    &.ng-valid {
      border-color: $color-frog;
    }
  }

  &[readonly] {
    background-color: $color-anchovy;
  }
}

.arag-icon-card-input {
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:focus-within {
    outline: 0;
    box-shadow: 0 0 0 2px $color-peacock;
  }

  .card-body {
    padding: $space-one;

    &.arag-icon {
      // adjust gap between icon and text... specing for 'xxl' (default) is a little too much to fit a badge if not .vertical
      padding-left: $space-four;

      &::before {
        // adjust icon size... desired size is between current 'xl' and 'xxl'
        width: 3.2rem;
        font-size: 3.2rem;
        line-height: 3.2rem;
      }
    }

    &:hover {
      cursor: pointer;
    }

    .card-title {
      @include arag-font-size("medium");
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      line-height: 3.2rem;
      color: $color-elephant;
    }

    .card-text {
      @include arag-font-size("small");
      margin-top: -$space-quarter;
      font-weight: $font-weight-bold;
    }
  }

  .form-check-input {
    z-index: -1;
    opacity: 0;
  }

  .form-control.form-check-input {
    height: 0;
    padding: 0;
  }
}

.password-help-link {
  &::before {
    @include arag-icon("show");
  }

  &.password-shown {
    &::before {
      @include arag-icon("hide");
    }
  }
}

.arag-icon-list {
  .selected-card {
    background-color: $color-dove;
    border-color: $color-utility-success;
    .card-body {
      &::before {
        color: $color-utility-success;
      }
    }

    &:hover {
      background-color: $color-shoebill;
    }
  }

  .invalid-card {
    border-color: $color-ladybug;
  }
}
