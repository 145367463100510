$navigation-menu-child-color: #99c4cd !default;

.navigation {
  padding-inline-start: 0;
  list-style: none;
  > * .menu-link {
    &:not(.menu-icon) {
      padding-left: 5.6rem;
    }
    &.menu-icon {
      @include arag-icon-init("md", "left", $vertical-align: "middle", $margin: $space-two);

      &::before {
        margin-left: $space-one;
        color: $navigation-menu-child-color;
      }
      @each $icon, $content in $arag-icons {
        &.menu-icon-#{$icon} {
          &::before {
            @include arag-icon($icon);
            display: flex;
          }
        }
      }
      &.active {
        &::before {
          color: $color-parakeet;
        }
      }
    }
    &.parent {
      @include arag-icon-init("md", "right", $vertical-align: "middle", $margin: $space-one);
      &::after {
        @include arag-icon("down");
        padding-right: $space-one-and-half;
        color: $navigation-menu-child-color;
        transition: transform .35s;
        transform-origin: left;
      }
      &.open {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
    &.external {
      @include arag-icon-init("md", "right", $vertical-align: "middle", $margin: $space-one-and-half);
      &::after {
        @include arag-icon("external-link");
        padding-right: $space-one-and-half;
        color: $navigation-menu-child-color;
      }
    }
  }

  .child-menu {
    padding: 0;
    list-style-type: none;
    background: $color-iguana;
    .menu-link {
      @include arag-font-size("small");
      &.menu-icon {
        &::before {
          width: 3rem;
          min-width: 3rem;
          padding-left: $space-quarter;
        }
      }
    }
  }
}
