.deadline-tracker {
  display: flex;
  flex-flow: row wrap;
  gap: $space-quarter;
  align-items: center;
  justify-content: center;
  padding: $space-quarter;

  .deadline {
    text-align: right;
  }

  .deadline-due-date {
    @include arag-font-size("small");
    color: $color-crow;

    @include media-breakpoint-up(sm) {
      @include arag-font-size("medium");
    }
  }

  .deadline-due-date-label {
    color: $color-elephant;
    @include arag-font-size("micro");

    @include media-breakpoint-up(sm) {
      @include arag-font-size("extra-small");
    }
  }

  .deadline-status-message {
    padding-top: 4px;
    font-size: 2rem; // match .h2
    color: $color-arag-blue;
    @include arag-icon-init($size: "lg", $margin: 0);

    &::before {
      top: 8px;
      color: $color-arag-pink;
      @include arag-icon("calendar-important");
    }

    @include media-breakpoint-up(sm) {
      font-size: 2.4rem; // match .h2
      @include arag-icon-init($size: "xl");

      &::before {
        top: 6px;
      }
    }
  }

  &.deadline-tracker-overdue {
    .deadline-status-message {
      color: $color-utility-danger-text;

      &::before {
        color: inherit;
        content: fa-content($fa-var-calendar-exclamation);
      }
    }
  }

  &.deadline-tracker-complete {
    .deadline-status-message {
      color: $color-utility-success-text;

      &::before {
        color: inherit;
        content: fa-content($fa-var-calendar-check);
      }
    }
  }
}
