a {
  font-weight: $nav-link-font-weight;

  &:focus {
    outline: $link-focus-indicator-width solid $link-focus-indicator-color;
  }

  &:hover {
    text-decoration: underline;
  }
}
