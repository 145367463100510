@import "@fortawesome/fontawesome-pro/scss/functions";
@import "@fortawesome/fontawesome-pro/scss/variables";
@import "@fortawesome/fontawesome-pro/scss/mixins";
@import "../icons/arag-custom-icons";

// -- ARAG Icons to use
$arag-icons: (
  //
  //  FontAwesome Solid
  //
  "danger":               (type: "fass", content: $fa-var-times-circle),
  "info":                 (type: "fas", content: $fa-var-info-circle),
  "success":              (type: "fas", content: $fa-var-check-circle),
  "warning":              (type: "fas", content: $fa-var-exclamation-circle),
  "close":                (type: "fas", content: $fa-var-times),
  "help":                 (type: "fas", content: $fa-var-question),
  "sign-out":             (type: "fas", content: $fa-var-sign-out-alt),
  "file":                 (type: "fas", content: $fa-var-file-alt),
  "print":                (type: "fas", content: $fa-var-print),
  "export":               (type: "fas", content: $fa-var-file-export),
  "list-bullet":          (type: "fas", content: $fa-var-circle-small),
  "comment":              (type: "fas", content: $fa-var-comment),
  "envelope":             (type: "fas", content: $fa-var-envelope),
  "check":                (type: "fas", content: $fa-var-check),
  "percent":              (type: "fas", content: $fa-var-percent),
  "plus":                 (type: "fas", content: $fa-var-plus),
  "minus":                (type: "fas", content: $fa-var-minus),
  "times":                (type: "fas", content: $fa-var-times-circle),
  "check-circle":         (type: "fas", content: $fa-var-check-circle),
  "plus-circle":          (type: "fass", content: $fa-var-plus-circle),
  "download":             (type: "fas", content: $fa-var-arrow-circle-down),
  "action-needed":        (type: "fas", content: $fa-var-arrow-circle-right),
  "question":             (type: "fas", content: $fa-var-question-circle),
  "hourglass":            (type: "fas", content: $fa-var-hourglass-half),
  "left":                 (type: "fas", content: $fa-var-chevron-left),
  "first":                (type: "fas", content: $fa-var-chevron-double-left),
  "right":                (type: "fas", content: $fa-var-chevron-right),
  "last":                 (type: "fas", content: $fa-var-chevron-double-right),
  "up":                   (type: "fas", content: $fa-var-chevron-up),
  "down":                 (type: "fas", content: $fa-var-chevron-down),
  "external-link":        (type: "fas", content: $fa-var-external-link),
  "contact-info":         (type: "fas", content: $fa-var-address-card),
  "filter":               (type: "fas", content: $fa-var-filter),
  "sort":                 (type: "fas", content: $fa-var-sort),
  "sort-up":              (type: "fas", content: $fa-var-sort-up),
  "sort-down":            (type: "fas", content: $fa-var-sort-down),
  "chat":                 (type: "fas", content: $fa-var-comment),
  "user":                 (type: "fas", content: $fa-var-user-alt),
  "default-icon":         (type: "fas", content: $fa-var-dot-circle),
  "eye":                  (type: "fas", content: $fa-var-eye),
  "university":           (type: "fas", content: $fa-var-university),
  "shield":               (type: "fas", content: $fa-var-shield-alt),
  "briefcase":            (type: "fas", content: $fa-var-briefcase),
  "tools":                (type: "fas", content: $fa-var-wrench),
  "settings":             (type: "fas", content: $fa-var-cog),
  "arrow-right":          (type: "fas", content: $fa-var-arrow-right),
  "money":                (type: "fas", content: $fa-var-dollar-sign),
  "scales":               (type: "fas", content: $fa-var-balance-scale),
  "coins":                (type: "fas", content: $fa-var-coins),
  "phone":                (type: "fas", content: $fa-var-phone-alt),
  "map-marker":           (type: "fas", content: $fa-var-map-marker-alt),
  "pie":                  (type: "fas", content: $fa-var-chart-pie),
  "bar":                  (type: "fas", content: $fa-var-chart-bar),
  "search":               (type: "fas", content: $fa-var-search),
  "invoice":              (type: "fas", content: $fa-var-file-invoice-dollar),
  "gavel":                (type: "fas", content: $fa-var-gavel),
  "users":                (type: "fas", content: $fa-var-users),
  "identity":             (type: "fas", content: $fa-var-fingerprint),
  "bookmark":             (type: "fas", content: $fa-var-bookmark),
  "certificate":          (type: "fas", content: $fa-var-file-certificate),
  "money-funnel":         (type: "fas", content: $fa-var-funnel-dollar),
  "book":                 (type: "fas", content: $fa-var-book-open),
  "file-search":          (type: "fas", content: $fa-var-file-search),
  "show":                 (type: "fas", content: $fa-var-eye),
  "hide":                 (type: "fas", content: $fa-var-eye-slash),
  "upload":               (type: "fas", content: $fa-var-cloud-upload),
  "file-success":         (type: "fas", content: $fa-var-file-check),
  "envelope-open":        (type: "fas", content: $fa-var-envelope-open-text),
  "calendar":             (type: "fas", content: $fa-var-calendar-alt),
  "copy":                 (type: "fas", content: $fa-var-copy),
  "user-settings":        (type: "fas", content: $fa-var-user-cog),
  "time":                 (type: "fas", content: $fa-var-watch),
  "home":                 (type: "fas", content: $fa-var-home),
  "save":                 (type: "fas", content: $fa-var-save),
  "edit":                 (type: "fas", content: $fa-var-pencil),
  "rss":                  (type: "fas", content: $fa-var-rss),
  "payment-check":        (type: "fas", content: $fa-var-money-check-alt),
  "tags":                 (type: "fas", content: $fa-var-tags),
  "play-video":           (type: "fas", content: $fa-var-play-circle),
  "ban":                  (type: "fas", content: $fa-var-ban),
  "warning-triangle":     (type: "fas", content: $fa-var-exclamation-triangle),
  "grid":                 (type: "fas", content: $fa-var-grip-horizontal),
  "actions":              (type: "fas", content: $fa-var-ellipsis-v),
  "calendar-add":         (type: "fas", content: $fa-var-calendar-plus),
  "calendar-important":   (type: "fas", content: $fa-var-calendar-star),
  "clock":                (type: "fas", content: $fa-var-clock),
  "dashboard":            (type: "fas", content: $fa-var-tachometer-alt-slow),
  "documents":            (type: "fas", content: $fa-var-folders),
  "link":                 (type: "fas", content: $fa-var-link),
  "menu":                 (type: "fas", content: $fa-var-bars),
  "tasks":                (type: "fas", content: $fa-var-tasks),
  "pause":                (type: "fas", content: $fa-var-pause-circle),
  "share":                (type: "fas", content: $fa-var-share-alt),
  "building":             (type: "fas", content: $fa-var-building),
  "laptop":               (type: "fas", content: $fa-var-laptop),
  "calendar-alert":       (type: "fas", content: $fa-var-calendar-exclamation),
  "calendar-check":       (type: "fas", content: $fa-var-calendar-check),
  "lock":                 (type: "fas", content: $fa-var-lock),
  "circle-quarter":       (type: "fas", content: $fa-var-circle-quarter-stroke),
  "circle-half":          (type: "fas", content: $fa-var-circle-half-stroke),
  "circle-three-quarters":(type: "fas", content: $fa-var-circle-three-quarters-stroke),
  "circle-full":          (type: "fas", content: $fa-var-circle),
  //
  // ARAG Custom Icons
  //
  "attorneys":            (type: "arag", content: $arag-icon-0001_attorneys),
  "employers":            (type: "arag", content: $arag-icon-0002_employers),
  "individuals":          (type: "arag", content: $arag-icon-0003_individuals),
  "check-mark":           (type: "arag", content: $arag-icon-0004_check-mark),
  "error-report":         (type: "arag", content: $arag-icon-0005_error-report),
  // NOTE:  The 'circle', 'code', and 'rocket' icons are from the FontAwesome Light family but are being added to the
  //        custom ARAG font file rather than bringing in another font file for just a few outliers.
  //        (all of this is subject to change in a future release)
  "empty-circle":         (type: "arag", content: $arag-icon-0006-fa-light-circle),
  "code":                 (type: "arag", content: $arag-icon-0007-fa-light-code),
  "get-started":          (type: "arag", content: $arag-icon-0008-fa-light-rocket),
  // ------------------------------------------------------------------------------
  "agreement":            (type: "arag", content: $arag-icon-0009_agreement),
  "attorney-finder":      (type: "arag", content: $arag-icon-0010_attorney-finder),
  "attorney-map":         (type: "arag", content: $arag-icon-0011_attorney-map),
  "attorney-website":     (type: "arag", content: $arag-icon-0012_attorney-website),
  "briefcase-alt":        (type: "arag", content: $arag-icon-0013_briefcase),
  "buildings":            (type: "arag", content: $arag-icon-0014_buildings),
  "calendar-scheduling":  (type: "arag", content: $arag-icon-0015_calendar-scheduling),
  "confirm-document":     (type: "arag", content: $arag-icon-0016_confirmation),
  "debt-collection":      (type: "arag", content: $arag-icon-0017_debt-collection),
  "direct-deposit":       (type: "arag", content: $arag-icon-0018_direct-deposit),
  "document-review":      (type: "arag", content: $arag-icon-0019_document-review),
  "experienced-attorney": (type: "arag", content: $arag-icon-0020_experienced-attorney),
  "handshake":            (type: "arag", content: $arag-icon-0021_handshake),
  "identity-theft":       (type: "arag", content: $arag-icon-0022_identity-theft),
  "late-adulthood":       (type: "arag", content: $arag-icon-0023_late-adulthood),
  "attorney-location":    (type: "arag", content: $arag-icon-0024_locator),
  "give-money":           (type: "arag", content: $arag-icon-0025_money),
  "lose-money":           (type: "arag", content: $arag-icon-0026_money-with-arrow),
  "network-guarantee":    (type: "arag", content: $arag-icon-0027_network-guarantee),
  "new":                  (type: "arag", content: $arag-icon-0028_new),
  "online-resources":     (type: "arag", content: $arag-icon-0029_online-resources),
  "paid":                 (type: "arag", content: $arag-icon-0030_paid),
  "push-button":          (type: "arag", content: $arag-icon-0031_push-button-start),
  "recruiting":           (type: "arag", content: $arag-icon-0032_recruiting),
  "will-preparation":     (type: "arag", content: $arag-icon-0033_standard-will-preperation),
  "tax-review":           (type: "arag", content: $arag-icon-0034_tax-review),
  "attorney-call":        (type: "arag", content: $arag-icon-0035_telephone-ringing),
  "traffic-ticket":       (type: "arag", content: $arag-icon-0036_traffic-ticket),
  "your-case":            (type: "arag", content: $arag-icon-0037_your-case),
  "advice":               (type: "arag", content: $arag-icon-0038_advice),
  "messaging":            (type: "arag", content: $arag-icon-0039_messaging),
  "graduate":             (type: "arag", content: $arag-icon-0040_graduate),
  "desktop":              (type: "arag", content: $arag-icon-0041_desktop),
  "consumer-protection":  (type: "arag", content: $arag-icon-0042_consumer-protection),
  "judgement":            (type: "arag", content: $arag-icon-0043_judgement),
  "credit-lock":          (type: "arag", content: $arag-icon-0044_credit-lock),
  "pick-lawyer":          (type: "arag", content: $arag-icon-0045_pick-lawyer),
  "in-office":            (type: "arag", content: $arag-icon-0046_in-office),
  "legal-problem":        (type: "arag", content: $arag-icon-0047_legal-problem),
  "money-cash":           (type: "arag", content: $arag-icon-0048_money-cash),
  "certified":            (type: "arag", content: $arag-icon-0049_certified),
  "producers":            (type: "arag", content: $arag-icon-0050_producers),
  "keys":                 (type: "arag", content: $arag-icon-0051_keys),
  //
  // FontAwesome Brands
  //
  "twitter":              (type: "fab", content: $fa-var-twitter-square),
  "pinterest":            (type: "fab", content: $fa-var-pinterest-square),
  "linkedin":             (type: "fab", content: $fa-var-linkedin),
  "youtube":              (type: "fab", content: $fa-var-youtube),
  "facebook":             (type: "fab", content: $fa-var-facebook-square),
  "vimeo":                (type: "fab", content: $fa-var-vimeo),
) !default;

$arag-icon-sizes: (
  "xxl": ("icon-size": 4.4rem, "box-size": 8rem, "card-top" : 1rem, "card-left": .6rem),
  "xl":  ("icon-size": 2.8rem, "box-size": 3.2rem, "card-top" : 2rem, "card-left": 2.2rem),
  "lg":  ("icon-size": 1.8rem, "box-size": 2.8rem, "card-top" : 2.2rem, "card-left": 2rem),
  "md":  ("icon-size": 1.6rem, "box-size": 2.4rem, "card-top" : 2.3rem, "card-left": 2.2rem),
  "sm":  ("icon-size": 1.2rem, "box-size": 2rem, "card-top" : 2.5rem, "card-left": 2.2rem),
) !default;

@mixin arag-icon-init($size: "md", $position: "left", $vertical-align: null, $margin: $space-half) {
  $icon-size-properties: map-get($arag-icon-sizes, $size);
  $box-size: map-get($icon-size-properties, "box-size");
  $icon-size: map-get($icon-size-properties, "icon-size");

  $before-or-after: "";
  @if ($position == "right") {
    $before-or-after: "after";
  }
  @else {
    $position: "left";
    $before-or-after: "before";
  }

  position: relative;
  padding-#{$position}: $box-size + $margin;

  &::#{$before-or-after} {
    position: absolute;
    top: 0;
    #{$position}: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: $box-size;
    height: $box-size;
    font-size: $icon-size;
    line-height: $box-size;
    text-align: center;

    @if $vertical-align == "middle" {
      height: 100%;
    }
  }
}

@mixin arag-icon-add($size: "md", $position: "left", $between: $space-half, $vertical-align: null) {
  $icon-size-properties: map-get($arag-icon-sizes, $size);
  $box-size: map-get($icon-size-properties, "box-size");
  $icon-size: map-get($icon-size-properties, "icon-size");

  $before-or-after: "";
  @if ($position == "right") {
    $before-or-after: "after";
  }
  @else {
    $position: "left";
    $before-or-after: "before";
  }

  position: relative;
  // padding-#{$position}: $box-size + $between;

  &::#{$before-or-after} {
    width: $box-size;
    height: $box-size;
    font-size: $icon-size;
    line-height: 1;
    text-align: center;

    @if $vertical-align == "middle" {
      height: 100%;
    }
    @else {
      padding-top: calc(($box-size - $icon-size) / 2);
      padding-bottom: calc(($box-size - $icon-size) / 2);
    }
  }
}

@mixin arag-icon($icon-name) {
  @if not map-has-key($arag-icons, $icon-name) {
    @error "'#{$icon-name}' is not a known icon name";
  } @else {
    $icon: map-get($arag-icons, $icon-name);
    $icon-type: map-get($icon, "type");
    $icon-content: map-get($icon, "content");

    @extend %#{$icon-type};
    content: fa-content(#{$icon-content});
  }
}

// Convenience function used to set content property
// (copied from FontAwesome's _variables.scss file to ensure it's available anywhere this file is imported)
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

// The following placeholder classes are taken from the corresponding FontAwesome base classes.
// Be sure to check these for changes when upgrading.
%far {
  @include fa-icon();
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

%fas {
  @include fa-icon();
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

%fass {
  @include fa-icon();
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 6 Sharp";
  font-weight: 900;
}

%fab {
  @include fa-icon();
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 6 Brands";
}

%arag {
  @include fa-icon();
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: arag-icon;
}
