// use this to override any base style rules that can't be controlled via Bootstrap variables...
html {
  font-size: 62.5%;   // browser default is typically 16px... this makes the base font 10px so relative sizing is easier e.g. 1.6rem = 16px
  body {
    --bs-body-font-size: 1.6rem; // $font-size-base is supposed to be setting this according to the documentation but it's not. Instead it's being set to calc(1.285rem + 0.42vw)
  }
  label {
    margin-bottom: .5rem; // was in old reset file
  }
}
